import { Injectable } from '@angular/core';
import { ServiceFactory, IBank, ICollection } from 'libraries/AonSDK/src/aon';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BankService extends CommonService{

  private bankService = ServiceFactory.createBankService();

  constructor(private http: HttpClient) {
    super();
    this.test();
  }

  async test() {
  }

  async getBankList(filter?: never): Promise<ICollection<IBank>> {
    return (await this.bankService.getCollection(filter)).result;
  }  

//////////////////////////////////////////////////////////////////////////////////////

  async getNordigenBank(key: string): Promise<IBank> {
    return (await this.bankService.getOneBank(key)).result;
  }

  async getBalances(){
    return (await this.bankService.getBalances()).result;
  }

  async getBalancesOfOneAccount(key : string){
    return (await this.bankService.getBalancesOfOneAccount(key)).result;
  }

  async getMovements(){
    return (await this.bankService.getMovements()).result;
  }

  async getMovementsOfOneAccount(key : string){
    return (await this.bankService.getMovementsOfOneAccount(key)).result;
  }

  async getLogo(){
    return (await this.bankService.getLogo()).result;
  }

  async getLogoOfOneBank(key : string){
    return (await this.bankService.getLogoOfOneBank(key)).result;
  }

  async getLinkedAccounts(){
    return (await this.bankService.getLinkedAccounts()).result;
  }

  async getUnlinkedAccounts(){
    return (await this.bankService.getUnlinkedAccounts()).result;
  }
 
  async getNordigenLink(key : string){
    return (await this.bankService.getNordigenLink(key)).result;
  }

 ///////////////////////////////////////////////////////////////////////////////////////

  async createBank(bank: IBank): Promise<IBank> {
    return (await this.bankService.createElement(bank)).result;
  }

  async updateBank(bank: IBank): Promise<IBank> {
    return (await this.bankService.updateElement(bank)).result;
  }

  async deleteBank(pkey: any): Promise<boolean> {
    return (await this.bankService.deleteElement(pkey)).result;
  }

}
