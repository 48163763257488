import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector    : 'app-grid-layout',
  templateUrl : './grid-layout.component.html',
  styleUrls   : ['./grid-layout.component.scss']
})
export class GridLayoutComponent implements OnInit {

  constructor() { 
  }

  ngOnInit(): void {
  }

}
