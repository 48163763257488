//
// File globals.ts
//
'use strict';
  // Roles
  export const predefinedRoles = {
    'ADMIN'     : 'ADMIN',
    'ENTERPRISE': 'ENTERPRISE',
    'EMPLOYEE'  : 'EMPLOYEE'
  };
  // Idioma
  export const predefinedLanguage: string = getBrowserLanguage();
  // Rutas
  export const globalRouterlinkBase: {[key: string]: string} = {
    'management'      : '/management',
    'tax-panel'       : '/tax-panel',
    'employees'       : '/employees-panel',
    'documentation'   : '/documentation',
    'inbox'           : '/inbox',
    'employees-view'  : '/employee-view'
  }
  export const globalRouterlink: {[key: string]: string} = {
    'management'      : globalRouterlinkBase['management']+'/billing/sales',
    'tax-panel'       : globalRouterlinkBase['tax-panel'],
    'employees'       : globalRouterlinkBase['employees'],
    'documentation'   : globalRouterlinkBase['documentation'],
    'inbox'           : globalRouterlinkBase['inbox'],
    'employees-view'  : globalRouterlinkBase['employees-view']
  }
  export const globalRouterlinkSectionsBase: {[key: string]: string} = {
    'billing'                : 'billing',
    'sales'                  : 'billing/sales',
    'sales/create'           : 'billing/sales/create/',
    'sales/create/factura'   : 'billing/sales/create/factura',
    'sales/edit'             : 'billing/sales/edit',
    'bills'                  : 'billing/bills',
    'bills/create'           : 'billing/bills/create/',
    'bills/edit'             : 'billing/bills/edit',
    'ticket'                 : 'billing/bills/ticket',
    'ticket/create'          : 'billing/bills/ticket/create/',
    'ticket/edit'            : 'billing/bills/ticket/edit/',
    'reports'                : 'billing/reports',
    'design'                 : 'billing/design',
    'contact'                : 'billing/contact',
    'contact/create'         : 'billing/contact/create/',
    'contact/edit'           : 'billing/contact/edit/',
    'products'               : 'billing/products',
    'products/create'        : 'billing/products/create',
    'products/edit'          : 'billing/products/edit/',
    'banks'                  : 'treasury/banks',
    'banks/create'           : 'treasury/banks/create',
    'inbox/create'           : 'create',
    'employees/create'       : 'create',
    'employees/edit'         : 'edit',
    'employees/mark'         : 'mark',
  }
  export const globalRouterlinkSections: {[key: string]: string} = {
    'billing'                : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['billing'],
    'sales'                  : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['sales'],
    'sales/create'           : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['sales/create'],
    'sales/create/factura'   : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['sales/create/factura'],
    'sales/edit'             : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['sales/edit'],
    'bills'                  : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['bills'],
    'bills/create'           : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['bills/create'],
    'bills/edit'             : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['bills/edit'],
    'ticket'                 : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['ticket'],
    'ticket/create'          : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['ticket/create'],
    'ticket/edit'            : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['ticket/edit'],
    'reports'                : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['reports'],
    'design'                 : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['design'],
    'contact'                : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['contact'],
    'contact/create'         : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['contact/create'],
    'contact/edit'           : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['contact/edit'],
    'products'               : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['products'],
    'products/create'        : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['products/create'],
    'products/edit'          : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['products/edit'],
    'banks'                  : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['banks'],
    'banks/create'           : globalRouterlinkBase['management']+'/'+globalRouterlinkSectionsBase['banks/create'],
    'inbox/create'           : globalRouterlinkBase['inbox']+'/'+globalRouterlinkSectionsBase['inbox/create'],
    'employees/create'       : globalRouterlinkBase['employees']+'/'+globalRouterlinkSectionsBase['employees/create'],
    'employees/edit'       : globalRouterlinkBase['employees']+'/'+globalRouterlinkSectionsBase['employees/edit'],
    'employees/mark'         : globalRouterlinkBase['employees']+'/'+globalRouterlinkSectionsBase['employees/mark'],
  }
  // Icons add
  export const aon_add_icon: {[key: string]: string} = {
    'aon_note_add': `
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm-1 9h-2v3H8v2h3v3h2v-3h3v-2h-3v-3zm-7 9h12V9h-5V4H6v16z"
        />
      </svg>`,
    'aon_excel': `
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.17 3.25c.22 0 .417.083.59.25.16.16.24.353.24.58v15.84a.79.79 0 0 1-.24.58.829.829 0 0 1-.59.25H7.83a.829.829 0 0 1-.59-.25.79.79 0 0 1-.24-.58V17H2.83a.846.846 0 0 1-.59-.24.846.846 0 0 1-.24-.59V7.83c0-.22.08-.417.24-.59.173-.16.37-.24.59-.24H7V4.08c0-.227.08-.42.24-.58a.829.829 0 0 1 .59-.25M7 13.06l1.18 2.22h1.79L8 12.06l1.93-3.17H8.22L7.13 10.9l-.04.06-.03.07a23.69 23.69 0 0 0-.56-1.07c-.167-.353-.343-.71-.53-1.07H4.16l1.89 3.19L4 15.28h1.78m8.1 4.22V17H8.25v2.5m5.63-3.75v-3.12H12v3.12m1.88-4.37V8.25H12v3.13M13.88 7V4.5H8.25V7m12.5 12.5V17h-5.62v2.5m5.62-3.75v-3.12h-5.62v3.12m5.62-4.37V8.25h-5.62v3.13M20.75 7V4.5h-5.62V7h5.62z"
        />
      </svg>`,
    'aon_convert': `
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.132 20.1v-1.74h2.807l-.307-.303c-.96-.94-1.649-1.921-2.069-2.942-.42-1.02-.63-2.03-.63-3.032 0-1.88.558-3.555 1.672-5.022C5.72 5.594 7.172 4.606 8.962 4.097v1.96A6.015 6.015 0 0 0 5.96 8.391a6.417 6.417 0 0 0-1.129 3.692c0 .81.14 1.582.422 2.318.28.737.75 1.413 1.405 2.03l.546.493v-2.64h1.744V20.1H3.132zm17.884-8.667H19.11a6.176 6.176 0 0 0-.5-2.083 5.529 5.529 0 0 0-1.277-1.78l-.544-.494v2.64h-1.746V3.9h5.816v1.74h-2.824l.324.303c.881.856 1.53 1.754 1.946 2.694.416.94.653 1.872.71 2.796zm-3.863 11.72-.196-1.325a4.593 4.593 0 0 1-.839-.365 3.34 3.34 0 0 1-.72-.52l-1.203.491-.982-1.574 1.042-.848a3.78 3.78 0 0 1-.107-.878c.002-.27.038-.562.107-.878l-1.042-.852.982-1.585 1.203.486c.197-.169.437-.332.72-.49.284-.16.564-.283.84-.372l.195-1.343h1.792l.202 1.343c.278.089.56.214.843.374.284.161.523.33.72.504l1.209-.502.976 1.602-1.036.851c.07.31.105.6.103.87a4.283 4.283 0 0 1-.103.87l1.036.848-.976 1.574-1.21-.491c-.196.19-.436.364-.72.52a4.547 4.547 0 0 1-.842.365l-.202 1.326h-1.792zm.893-2.696c.659 0 1.212-.224 1.66-.673.448-.448.672-1.001.672-1.66 0-.66-.224-1.212-.672-1.658-.448-.446-1.001-.67-1.66-.67-.66 0-1.212.224-1.658.67-.446.446-.67.999-.67 1.658 0 .659.224 1.212.67 1.66.446.449.999.673 1.658.673z"
        />
      </svg>`,
    'aon_build': `
      <svg viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="m33.374 24.36 22.322 22.322c.983.984.983 2.533 0 3.467l-5.654 5.654a2.448 2.448 0 0 1-3.466 0L24.18 33.408c-5.703 2.138-12.365.934-16.938-3.639-5.654-5.63-6.17-14.455-1.598-20.699l9.416 9.415 3.49-3.466-9.44-9.464C15.38.982 24.18 1.498 29.835 7.152a15.992 15.992 0 0 1 3.54 17.209zm-8.309 3 23.256 23.256 2.163-2.188-23.23-23.231c1.13-1.45 1.867-3.073 2.162-4.819a10.985 10.985 0 0 0-3.048-9.735 11.084 11.084 0 0 0-8.456-3.22l7.596 7.596-10.424 10.423-7.596-7.596a10.983 10.983 0 0 0 3.245 8.457c2.557 2.557 6.072 3.564 9.416 3.073a10.748 10.748 0 0 0 4.916-2.016z"
        />
      </svg>`,
    'aon_upload': `
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 16.5v-6h4l-7-7-7 7h4v6h6zM12 6.33l2.17 2.17H13v6h-2v-6H9.83L12 6.33zm7 14.17v-2H5v2h14z"
        />
      </svg>`,
    'aon_entrance': `
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3 24v-2H22V2h-9.7V0H22c.533 0 1 .2 1.4.6.4.4.6.867.6 1.4v20c0 .533-.2 1-.6 1.4-.4.4-.867.6-1.4.6h-9.7zm-2.6-6.167L8.267 16.4l3.4-3.4H0v-2h11.6L8.2 7.6l1.433-1.433 5.867 5.866-5.8 5.8z"
        />
      </svg>`,
    'aon_exit': `
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 24c-.533 0-1-.2-1.4-.6C.2 23 0 22.533 0 22V2C0 1.467.2 1 .6.6 1 .2 1.467 0 2 0h9.7v2H2v20h9.7v2H2zm16.2-6.167L16.767 16.4l3.4-3.4H8.5v-2h11.6l-3.4-3.4 1.433-1.433L24 12.033l-5.8 5.8z"
        />
      </svg>`,
    'scan_delete': `
      <svg  viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M240-800v200-200 640-9.5 9.5-640Zm0 720q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v174q-19-7-39-10.5t-41-3.5v-120H520v-200H240v640h254q8 23 20 43t28 37H240Zm396-20-56-56 84-84-84-84 56-56 84 84 84-84 56 56-83 84 83 84-56 56-84-83-84 83Z"/>
      </svg>`,
  };

  // Coger el idioma del navegador
  function getBrowserLanguage(): string {
    const languageList: { [key: string]: string } =  {
     'en-US' : 'en',
     'es-ES' : 'es'
    }
    const language = window.navigator.language || (window.navigator as any).userLanguage;

    // Si no tenemos cogemos como predefinido 'es'
    return languageList[language] ? languageList[language] : 'es';
  }
