import { IEnterprise, IRegistryEnterprise, MediaType } from "../interfaces/modelsInterfaces";
import { IEnterpriseSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IFilter, ICollection } from "../interfaces/utilitiesInterfaces";
import { Enterprise, EnterpriseJSON } from "../models/Enterprise";
import { RegistryEnterprise, RegistryEnterpriseJSON } from "../models/RegistryEnterprise";
import { COMPANY, DOCUMENT_URL, REGISTRY_URL } from "../utils/ApiUrls";
import { Collection } from "../utils/Collection";
import { BASE_URL, DOMAINS, GET_MULTIPLE, GET_SINGLE } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { ErrorResponse } from "../utils/Response";
import { Media } from "../models/Media";
import { AuthenticationManager } from "../services/AuthenticationService";
import { APIAuthenticationRepository } from "./AuthenticationRepository";

export class EnterpriseRepository extends GenericRepository<Enterprise> implements IEnterpriseSpecificMethodsRepository, IMultipleObjectCrudRepository<Enterprise>, ISingleObjectCrudRepository<Enterprise> {

    /**
     * Obtiene una colección de empresas basada en el filtro proporcionado.
     *
     * @param {IFilter | undefined} filter - Un filtro opcional para aplicar a la colección.
     * @return {Promise<ICollection<Enterprise>>} Una promesa que se resuelve en una colección de empresas.
     */
    async getCollection(filter?: IFilter | undefined): Promise<ICollection<Enterprise>> {
        let json = {}
        let headers = {}
        if(DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS] != undefined){
            json = {
                parentId: DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS].id
            }
            headers = {
                domain_id: DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS].id,
                domain_name: DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS].name
            }
        }
        const url = ApiHttpRequest.makeURL(BASE_URL + COMPANY.COMPANYSWITHROLES, json);
        let collection: ICollection<Enterprise> = new Collection<Enterprise>();
        let response = await ApiHttpRequest.get(url, headers, {})
        if((response?.type! == 'error')){
            if(collection.size() == 0){
                new AuthenticationManager(new APIAuthenticationRepository()).logout();
                throw new ErrorResponse('0101')
            }
            return collection;
        }
        // Verificamos los permisos de cada empresa y la agregamos a la colección
        //   const permissionPromise = response.map((element: any) => {
        //     return this.authManager.checkPermission(element.domain).then((result) => {
        //       if (result.result)
        //         collection.add(this.apiModel.parseDataToReceive(element, GET_MULTIPLE, filter));
        //     });
        //     // collection.add(this.apiModel.parseDataToReceive(element, GET_MULTIPLE, filter));
        //   });
        //   await Promise.all(permissionPromise);  // Aseguramos que se resuelva promesa antes de continuar
        response.forEach((element: any) => {
            collection.add(EnterpriseJSON.parseDataToReceive(element, GET_MULTIPLE));
        });
        collection.forEach((element: Enterprise) => {
            if (element.Key == '' || element.Name == '') {
                collection.remove(element.getKey());
            }
        });
        return collection;
    }

    async get(key: string, type?: string | undefined): Promise<Enterprise> {
        let response: IEnterprise = await ApiHttpRequest.get(COMPANY.ONE, {}, {})
        return EnterpriseJSON.parseDataToReceive(response, GET_SINGLE);
    }

    async getCurrentEnterpriseAvatar(): Promise<any>{
        let params = {
            attachType: 'registry',
            attachModule: localStorage.getItem('idEnterprise'),
            type: 0,
        }
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + DOCUMENT_URL.ATTACH, params), {}, {});
        if(!(response?.type! == 'error')){
            let file = {
                "domain_id": localStorage.getItem('domainId'),
                "attach_type": "REGISTRY",
                "domain_name": localStorage.getItem('domainName'),
                "id": response.id
            }
            let avatar = await ApiHttpRequest.get(BASE_URL + DOCUMENT_URL.FILE + atob(JSON.stringify(file)), {}, {});
            if(!(avatar?.type! == 'error')){
                console.log(avatar);
            } else {
                throw new ErrorResponse('0801');    
            }
        } else {
            throw new ErrorResponse('0802');
        }
    }

    async getCurrentEnterpriseData(): Promise<IEnterprise> {
        let response = await ApiHttpRequest.get(BASE_URL + REGISTRY_URL.GET_FULL_REGISTRY, {}, {});
        let enterprise: Enterprise = EnterpriseJSON.parseDataToReceive(response, 'data');
        return enterprise;
    }

    async updateCurrentEntepriseData(enterprise: Enterprise): Promise<IEnterprise> {
        let phones: Media[] = [], emails: Media[] = [], webs: Media[] = [];
        phones.push(enterprise.Phone[0] ? enterprise.Phone[0] : new Media(MediaType.PHONE));
        emails.push(enterprise.Email[0] ? enterprise.Email[0] : new Media(MediaType.EMAIL));
        webs.push(enterprise.Website[0] ? enterprise.Website[0] : new Media(MediaType.WEB));
        if(enterprise.Phone.length > 0){
            for(let i = 1; i < enterprise.Phone.length; i++){
                if(enterprise.Phone[i].Value != '')
                    phones.push(enterprise.Phone[i]);
            }
            enterprise.Phone = phones;
        }
        if(enterprise.Email.length > 0){
            for(let i = 1; i < enterprise.Email.length; i++){
                if(enterprise.Email[i].Value != '')
                    emails.push(enterprise.Email[i]);
            }
            enterprise.Email = emails;
        }
        if(enterprise.Website.length > 0){
            for(let i = 1; i < enterprise.Website.length; i++){
                if(enterprise.Website[i].Value != '')
                    webs.push(enterprise.Website[i]);
            }
            enterprise.Website = webs;
        }
        let response = await ApiHttpRequest.put(BASE_URL + REGISTRY_URL.SAVE_FULL_REGISTRY, {}, await EnterpriseJSON.parseDataToSend(enterprise));
        return enterprise;
    }

    async getCurrentEnterpriseRegistryData(): Promise<IRegistryEnterprise> {
        let response = await ApiHttpRequest.get(BASE_URL + REGISTRY_URL.GET_FULL_REGISTRY, {}, {});
        let registryEnterprise: RegistryEnterprise = RegistryEnterpriseJSON.parseDataToReceive(response);
        return registryEnterprise;
    }

    async updateCurrentEnterpriseRegistryData(registryEnterprise: RegistryEnterprise): Promise<IRegistryEnterprise> {
      let response = await ApiHttpRequest.put(BASE_URL + REGISTRY_URL.SAVE_FULL_REGISTRY, {}, await RegistryEnterpriseJSON.parseDataToSend(registryEnterprise));

        return registryEnterprise;
    }
}