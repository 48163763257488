import { IFolder } from "../interfaces/modelsInterfaces";
import { ICollection, IModel } from "../interfaces/utilitiesInterfaces";
import { Collection } from "../utils/Collection";
import { ErrorResponse } from "../utils/Response";

export class Folder implements IFolder, IModel {
    private name: string;
    private path: string;
    private parent: string;
    private key: string;
    private fromUser: boolean;
    private idList: string[] = [];
    protected apiObject: any;

    public get FromUser(): any {
        return this.fromUser;
    }

    public set FromUser(value: any) {
        this.fromUser = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get IdList(): string[] {
        return this.idList;
    }

    public set IdList(value: string[]) {
        this.idList = value;
    }

    constructor(name?: string, parent?: string, fromUser?: boolean, idList?: string[]) {
        this.name = name || '';
        this.path = parent?.toLocaleLowerCase().split(' ').join('_') + '/' + name?.toLocaleLowerCase().split(' ').join('_') || '';
        this.parent = parent || '';
        this.key = this.path || '';
        this.fromUser = fromUser ? fromUser : false;
        this.idList = idList || [];
    }
    getName(): string {
        return this.name;
    }
    setName(value: string): IFolder {
        this.name = value;
        return this
    }
    getPath(): string {
        return this.path;
    }
    setPath(value: string): IFolder {
        this.path = value;
        return this
    }
    getParent(): string {
        return this.parent;
    }
    setParent(value: string): IFolder {
        this.parent = value;
        return this
    }

    getIdList(): string[] {
        return this.idList;
    }

    setIdList(value: string[]): IFolder {
        this.idList = value;
        return this
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    public get Path(): string {
        return this.path;
    }

    public set Path(value: string) {
        this.path = value;
    }

    public get Parent(): string {
        return this.parent;
    }

    public set Parent(value: string) {
        this.parent = value;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    getKey(): string {
        return this.path;
    }

    getFilterableFields(): Map<string,any> {
        let map = new Map<string, any>();
        map.set('name', this.Name);
        map.set('parent', this.Parent);
        return map;
    }

    getSortableFields(): Map<string,any> {
        let map = new Map<string, any>();
        map.set('name', this.Name);
        map.set('parent', this.Parent);
        return map;
    }
}

export class FolderJSON {
    static parseDataToSend(data: any) {
        throw new ErrorResponse('0199')
    }

    static parseDataToReceive(data: any) {
        let folder = new Folder();
        folder.ApiObject = data;
        folder.Key = data.document ? data.document : '';
        folder.Name = data.name ? data.name : '';
        folder.Parent = '/laboral';
        folder.IdList = data.contract_ids ? data.contract_ids : [];
        folder.Path = '/laboral/' + (data.contract_ids  && data.contract_ids.length > 0 ? data.contract_ids.join(',') : data.contract_ids[0]);
        return folder;
    }
}

export let folders: ICollection<Folder> = new Collection<Folder>();
export function setFolders(value: any) { folders = value; };
export let api: ICollection<Folder> = new Collection<Folder>();
export function setApi(value: any) { api = value; };
export let apiFolders = new Collection<Folder>();

// apiFolders.add(new Folder('Proforma', ''));
// apiFolders.add(new Folder('A contabilizar', ''));
// apiFolders.add(new Folder('Contabilizado', ''));
// apiFolders.add(new Folder('Papelera', ''));
apiFolders.add(new Folder('Documentos', '', true));
apiFolders.add(new Folder('Fiscal', '', false));
apiFolders.add(new Folder('Laboral', '', false));