import { AonFilter } from "../utils/AonFilter";
import { ContactFields, ContactOrder, ContractFields, DocumentFields, FolderFields, InvoiceFields, InvoiceOrder, InvoiceSerieFields, MarkFields, MessageChatFields, MessageFields, MessageIntervalFields, ProductFields, ProductOrder, SalaryFields, TaskHolderFields, TaxModelFields } from "../utils/ModelsFields";

export class FilterFactory {
    static createInvoiceFilter(): AonFilter<InvoiceFields, never, InvoiceOrder> {
        return new AonFilter<InvoiceFields, never, InvoiceOrder>();
    }

    static createInvoiceSerieFilter(): AonFilter<InvoiceSerieFields, never, never> {
        return new AonFilter<InvoiceSerieFields, never, never>();
    }

    static createContactFilter(): AonFilter<ContactFields, never, ContactOrder> {
        return new AonFilter<ContactFields, never, ContactOrder>();
    }

    static createProductFilter(): AonFilter<ProductFields, never, ProductOrder> {
        return new AonFilter<ProductFields, never, ProductOrder>();
    }

    static createMessageFilter(): AonFilter<MessageFields, MessageIntervalFields, never> {
        return new AonFilter<MessageFields, MessageIntervalFields, never>();
    }

    static createMessageChatFilter(): AonFilter<MessageChatFields, never, never> {
        return new AonFilter<MessageChatFields, never, never>();
    }

    static createTaxModelFilter(): AonFilter<TaxModelFields, never, never> {
        return new AonFilter<TaxModelFields, never, never>();
    }

    static createFolderFilter(): AonFilter<FolderFields, never, never> {
        return new AonFilter<FolderFields, never, never>();
    }

    static createDocumentFilter(): AonFilter<DocumentFields, never, never> {
        return new AonFilter<DocumentFields, never, never>();
    }

    static createContractFilter(): AonFilter<ContractFields, never, never> {
        return new AonFilter<ContractFields, never, never>();
    }

    static createMarkFilter(): AonFilter<MarkFields, never, never> {
        return new AonFilter<MarkFields, never, never>();
    }

    static createTaskHodlerFilter(): AonFilter<TaskHolderFields, never, never> {
        return new AonFilter<TaskHolderFields, never, never>();
    }

    static createSalaryFilter(): AonFilter<SalaryFields, never, never> {
        return new AonFilter<SalaryFields, never, never>();
    }
}