import { IBank } from "../interfaces/modelsInterfaces";
import { IBankNordigenSpecificMethods } from "../interfaces/serviceInterfaces";
import { IBankNordigenSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { ErrorResponse } from "../utils/Response";
import { Response } from "../utils/Response";
import { IResponse } from "../interfaces/utilitiesInterfaces";
import { GenericService } from "./GenericCrudService";
import { Bank } from "../models/Bank";


export class BankService extends GenericService<Bank, never> implements IBankNordigenSpecificMethods {
    protected SpecificMethodsRepository: IBankNordigenSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Bank> & ISingleObjectCrudRepository<Bank>, type: { new (): Bank }, SpecificMethodsRepository: IBankNordigenSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

   async getOneBank(iban: string): Promise<IResponse<IBank>> {
        try {
            return new Response<IBank>(this.SpecificMethodsRepository.getOneBank(iban));
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0703');
        }
    }

     async getBalances(): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getBalances());
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0704');
        }
    }

     async getBalancesOfOneAccount(iban: string): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getBalancesOfOneAccount(iban));
        }catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0704');
        }  
    }

    async getMovements(): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getMovements());
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0705');
        }
    }

     async getMovementsOfOneAccount(iban: string): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getMovementsOfOneAccount(iban));
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0705');
        }
    }

    async getLogo(): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getLogo());
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Bank');
        }
    }

     async getLogoOfOneBank(iban: string): Promise<IResponse<any>> {
        try{
            return new Response<any>(this.SpecificMethodsRepository.getLogoOfOneBank(iban));
        }catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Bank');
        }
    }

    async getLinkedAccounts(): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getLinkedAccounts());
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0706');
        }
    }

    async getUnlinkedAccounts(): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getUnlinkedAccounts());
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0707');
        }
    }

    async getNordigenLink(iban: string): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getNordigenLink(iban));
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Bank');
        }
    }
}

