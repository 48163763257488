import { CertificateSharedTypeList, CertificateSharedTypeListFromApi, CertificateTypeList, CertificateTypeListFromApi, ICertificate, ICertificateSharedType } from "../interfaces/modelsInterfaces";
import { IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { CertificateSharedType } from "./CertificateSharedType";
import { CertificateType } from "./CertificateType";

export class Certificate implements ICertificate, IModel {
    private name: string;
    private password: string;
    private sharedType: ICertificateSharedType;
    private representation: string;
    private expeditionDate: Date;
    private expirationDate: Date;
    private alias: string;
    private type: CertificateType;
    private tgss: boolean;
    private sepe: boolean;
    private aeat: boolean;
    private documentUser: string;
    private key: string;
    private isValid: boolean;
    protected apiObject: any;

    constructor(name?: string, sharedType?: ICertificateSharedType, expeditionDate?: Date, expirationDate?: Date, alias?: string, type?: CertificateType, tgss?: boolean, sepe?: boolean, aeat?: boolean, documentUser?: string, isValid?: boolean, representation?: string) {
        this.representation = representation || '';
        this.name = name || '';
        this.sharedType = sharedType || new CertificateSharedType(CertificateSharedTypeList.PERSONAL);
        this.expeditionDate = expeditionDate || new Date();
        this.expirationDate = expirationDate || new Date();
        this.alias = alias || '';
        this.type = type || new CertificateType(CertificateTypeList.PRIVADO);
        this.tgss = tgss || false;
        this.sepe = sepe || false;
        this.aeat = aeat || false;
        this.documentUser = documentUser || '';
        this.isValid = isValid || false;
        this.key = documentUser || '';
        this.password = '';
    }

    public get Password(): string {
        return this.password;
    }

    public set Password(value: string) {
        this.password = value;
    }

    public getPassword(): string {
        return this.password;
    }

    public setPassword(value: string): ICertificate {
        this.password = value;
        return this;
    }

    public get Representation(): string { 
        return this.representation;
    }

    public set Representation(value: string) {
        this.representation = value;
    }

    getRepresentation(): string {
        return this.representation;
    }

    setRepresentation(value: string): ICertificate {
        this.representation = value;
        return this
    }

    getName(): string {
        return this.name
    }

    setName(value: string): ICertificate {
        this.name = value;
        return this;
    }

    getSharedType(): ICertificateSharedType {
        return this.sharedType
    }

    setSharedType(value: ICertificateSharedType): ICertificate {
        this.sharedType = value;
        return this;
    }

    getExpeditionDate(): Date {
        return this.expeditionDate
    }

    setExpeditionDate(value: Date): ICertificate {
        this.expeditionDate = value;
        return this
    }

    getExpirationDate(): Date {
        return this.expirationDate
    }

    setExpirationDate(value: Date): ICertificate {
        this.expirationDate = value;
        return this
    }

    getAlias(): string {
        return this.alias
    }

    setAlias(value: string): ICertificate {
        this.alias = value;
        return this
    }

    getType(): CertificateType {
        return this.type
    }
    
    setType(value: CertificateType): ICertificate {
        this.type = value;
        return this
    }

    getTgss(): boolean {
        return this.tgss
    }

    setTgss(value: boolean): ICertificate {
        this.tgss = value;
        return this
    }

    getSepe(): boolean {
        return this.sepe
    }

    setSepe(value: boolean): ICertificate {
        this.sepe = value;
        return this
    }

    getAeat(): boolean {
        return this.aeat
    }

    setAeat(value: boolean): ICertificate {
        this.aeat = value;
        return this
    }

    getDocumentUser(): string {
        return this.documentUser
    }

    setDocumentUser(value: string): ICertificate {
        this.documentUser = value;
        return this
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get IsValid(): boolean{
        return this.isValid;
    }

    public set IsValid(value: boolean){
        this.isValid = value;
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    public get SharedType(): ICertificateSharedType {
        return this.sharedType;
    }

    public set SharedType(value: ICertificateSharedType) {
        this.sharedType = value;
    }

    public get ExpeditionDate(): Date {
        return this.expeditionDate;
    }

    public set ExpeditionDate(value: Date) {
        this.expeditionDate = value;
    }

    public get ExpirationDate(): Date {
        return this.expirationDate;
    }

    public set ExpirationDate(value: Date) {
        this.expirationDate = value;
    }

    public get Alias(): string {
        return this.alias;
    }

    public set Alias(value: string) {
        this.alias = value;
    }

    public get Type(): CertificateType {
        return this.type;
    }

    public set Type(value: CertificateType) {
        this.type = value;
    }

    public get Tgss(): boolean {
        return this.tgss;
    }

    public set Tgss(value: boolean) {
        this.tgss = value;
    }

    public get Sepe(): boolean {
        return this.sepe;
    }

    public set Sepe(value: boolean) {
        this.sepe = value;
    }

    public get Aeat(): boolean {
        return this.aeat;
    }

    public set Aeat(value: boolean) {
        this.aeat = value;
    }

    public get DocumentUser(): string {
        return this.documentUser;
    }

    public set DocumentUser(value: string) {
        this.documentUser = value;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string,any> {
        let map = new Map<string, any>();
        map.set('name', this.Name);
        map.set('representationType', this.sharedType);
        map.set('expeditionDate', this.ExpeditionDate);
        map.set('expirationDate', this.ExpirationDate);
        map.set('alias', this.Alias);
        map.set('type', this.Type);
        map.set('tgss', this.Tgss);
        map.set('sepe', this.Sepe);
        map.set('aeat', this.Aeat);
        map.set('documentUser', this.DocumentUser);
        return map;
    }

    getSortableFields(): Map<string,any> {
        let map = new Map<string, any>();
        map.set('name', this.Name);
        map.set('representationType', this.sharedType);
        map.set('expeditionDate', this.ExpeditionDate);
        map.set('expirationDate', this.ExpirationDate);
        map.set('alias', this.Alias);
        map.set('type', this.Type);
        map.set('tgss', this.Tgss);
        map.set('sepe', this.Sepe);
        map.set('aeat', this.Aeat);
        map.set('documentUser', this.DocumentUser);
        return map;
    }

}

export class CertificateJSON {
    static parseDataToSend(data: any, currentMethod: string, filter?: IFilter | undefined) {
        throw new Error("Method not implemented.");
    }

    static parseDataToReceive(data: any): Certificate {
        let certificate = new Certificate();
        certificate.ApiObject = data;
        certificate.Key = data.id ? data.id : '';
        certificate.Aeat = data.aeat ? true : false;
        certificate.Sepe = data.sepe ? true : false;
        certificate.Tgss = data.tgss ? true : false;
        certificate.ExpeditionDate = new Date(data.start_date);
        certificate.ExpirationDate = new Date(data.end_date);
        certificate.Alias = data.alias ? data.alias : '';
        certificate.DocumentUser = data.document ? data.document : '';
        certificate.IsValid = false;
        certificate.Representation = data.representation ? data.representation : '';
        certificate.SharedType = new CertificateSharedType(CertificateSharedTypeListFromApi[data.owner as keyof typeof CertificateSharedTypeListFromApi]);
        certificate.Type = new CertificateType(CertificateTypeListFromApi[data.type as keyof typeof CertificateTypeListFromApi]);
        certificate.Name = data.name ? data.name : '';
        return certificate;
    }
}