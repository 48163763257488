import { IProductStatus, IProductType, IProductClass, IProductCategory, ProductStatusList, ProductClassList, ProductTypeList, ProductTypeApiSend, ProductStatusApiSend, ProductClassApiSend } from "../interfaces/modelsInterfaces";
import { IMultipleObjectCrudRepository, IProductSpecificMethodsRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IFilter, ICollection, IOrderFilter } from "../interfaces/utilitiesInterfaces";
import { Product, ProductJSON } from "../models/Product";
import { ProductCategory, ProductCategoryJSON } from "../models/ProductCategory";
import { ProductClass } from "../models/ProductClass";
import { ProductStatus } from "../models/ProductStatus";
import { ProductType } from "../models/ProductType";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { ErrorResponse } from "../utils/Response";
import { PRODUCT_URL } from "../utils/ApiUrls";
import { ProductFields, ProductOrder } from "../utils/ModelsFields";
import { ProductFilter } from "../utils/ModelsFilters";

export class ProductRepository extends GenericRepository<Product> implements IProductSpecificMethodsRepository, IMultipleObjectCrudRepository<Product>, ISingleObjectCrudRepository<Product> {
    async getProductStatus(filter?: IFilter): Promise<ICollection<IProductStatus>> {
        let collection = new Collection<ProductStatus>();
        Object.values(ProductStatusList).forEach(product => {
            collection.add(new ProductStatus(product));
        })
        return collection;
    }

    async getProductTypes(filter?: IFilter): Promise<ICollection<IProductType>> {
        let collection = new Collection<ProductType>();
        Object.values(ProductTypeList).forEach(product => {
            collection.add(new ProductType(product));
        })
        return collection;
    }

    async getProductClasses(filter?: IFilter): Promise<ICollection<IProductClass>> {
        let collection = new Collection<ProductClass>();
        Object.values(ProductClassList).forEach(product => {
            collection.add(new ProductClass(product));
        })
        return collection;
    }

    async getProductCategories(filter?: IFilter): Promise<ICollection<IProductCategory>> {
        let collection = new Collection<ProductCategory>();
        let response = await ApiHttpRequest.get(BASE_URL + PRODUCT_URL.GET_PRODUCT_CATEGORY_LIST, {}, {});
        if(response)
            response.forEach((element: any) => {
                collection.add(ProductCategoryJSON.parseDataToReceive(element));
            });
        return collection;
    }

    async getProductCount(filter: IFilter): Promise<number> {
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + PRODUCT_URL.GET_PRODUCT_COUNT, generateFilters(filter)), {}, {});
        if(response)
            return response;
        else
            return 0;
    }

    async getCollection(filter: IFilter): Promise<ICollection<Product>> {
        let collection = new Collection<Product>();
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + PRODUCT_URL.GET_POST_PRODUCT_NEW, generateFilters(filter)), {}, {});
        if(response)
            response.forEach((element: any) => {
                collection.add(ProductJSON.parseDataToReceive(element));
            });
        return collection;
    }

    async get(key: string): Promise<Product> {
        let response = await ApiHttpRequest.get(BASE_URL + PRODUCT_URL.GET_POST_ITEM + '?product=' + key, {}, {});
        let responseProduct = await ApiHttpRequest.get(BASE_URL + PRODUCT_URL.GET_PRODUCT_LIST, {}, {});
        let responseProductV2 = await ApiHttpRequest.get(BASE_URL + PRODUCT_URL.GET_PRODUCT_LIST_V2, {}, {});
        if(response && responseProduct){
                let product;
                responseProduct.forEach((element: any) => {
                    if(element.id == key)
                        product = ProductJSON.parseDataToReceive(response, element);
                });
                responseProductV2.forEach((element: any) => {
                    if(element.id == key)
                        product = ProductJSON.parseDataToReceive(response, element);
                });
                if(product)
                    return product;
                else 
                    throw new ErrorResponse('1201');
        } else throw new ErrorResponse('1201');
    }

    async create(element: Product): Promise<Product> {
        let categoryJson = {
            domain: localStorage.getItem("domainId"),
            name: element.Category.Value,
            id: element.Category.getKey()
        }
        let jsonProduct  = {
            domain: {
                id: localStorage.getItem("domainId"),
                name: localStorage.getItem("domainName")
            },
            name: element.Name,
            code: element.Code,
            type: ProductTypeApiSend[element.Type.Value as keyof typeof ProductTypeApiSend],
            vat: element.Tax.getKey(),
            retention: element.IRPF.Percentage
        }
        let responseProduct = await ApiHttpRequest.get(BASE_URL + PRODUCT_URL.GET_PRODUCT_LIST, {}, {});
        responseProduct.forEach((element: any) => {
            if(element.code == jsonProduct.code)
                throw new ErrorResponse('1202');
        })
        if(!jsonProduct.type){
            throw new ErrorResponse('1203');
        }
        if(element.Category.Value != '')
            Object.defineProperty(jsonProduct, "category", {
                value: categoryJson,
                enumerable: true
            })
        // else{
        //     throw new ErrorResponse('0201','La categoria del producto es un campo obligatorio');
        // }
        let response = await ApiHttpRequest.post(BASE_URL + PRODUCT_URL.GET_POST_PRODUCT, {}, jsonProduct);
        if(!(response?.type! == 'error')){
            let jsonItem = {
                domain: {
                    id: localStorage.getItem("domainId"),
                    name: localStorage.getItem("domainName")
                },
                description: element.Description,
                barcode: element.BarCode,
                status: ProductStatusApiSend[element.Status.Value as keyof typeof ProductStatusApiSend],
                product: {
                    id: response.id,
                    domain: {
                        id: localStorage.getItem("domainId"),
                        name: localStorage.getItem("domainName"),
                        enableHeredity: false,
                        domainManagement: false,
                        active: false
                    },
                    name: element.Name,
                    code: element.Code,
                    brand: {},
                    status: ProductStatusApiSend[element.Status.Value as keyof typeof ProductStatusApiSend],
                    type: ProductTypeApiSend[element.Type.Value as keyof typeof ProductTypeApiSend],
                    kind: ProductClassApiSend[element.Class.Value as keyof typeof ProductClassApiSend],
                    vat: element.Tax.getKey(),
                    retention: element.IRPF.Percentage,
                    inventoriable: false,
                    serializable: false,
                    lotable: false,
                    manufactured: false,
                    composition: false,
                    compositionPrice: false,
                    packaged: false,
                    salesAccount: {
                        level: 0,
                        active: false,
                        entryEnabled: false
                    },
                    purchaseAccount: {
                        level: 0,
                        active: false,
                        entryEnabled: false
                    }
                },
                price: element.Price.toString(),
                profitPercent: element.Benefit,
                purchasePrice: element.CostPrice,
                removed: false
            }
            if(!jsonItem.product.kind){
                throw new ErrorResponse('1204');
            }
            if(jsonItem.product.vat != '0' && jsonItem.product.vat != '4' && jsonItem.product.vat != '10' && jsonItem.product.vat != '21'){
                throw new ErrorResponse('1205');
            } 
            if(element.Category.Value != '')
            Object.defineProperty(jsonItem.product, "category", {
                value: categoryJson,
                enumerable: true
            })
            let responseItem = await ApiHttpRequest.post(BASE_URL + PRODUCT_URL.GET_POST_ITEM, {}, jsonItem)
            element.Key = response.id ? response.id : '';
            if(!(responseItem?.type! == 'error'))
                return element;
            else
                throw new ErrorResponse('1211');
        }
        throw new ErrorResponse('1211');
    }

    async update(element: Product): Promise<Product> {
        if(element.ApiObject.item && element.ApiObject.product){
            element.ApiObject.item.name = element.Name;
            element.ApiObject.product.name = element.Name;
            element.ApiObject.item.barcode = element.BarCode;
            element.ApiObject.item.profitPercent = element.Benefit;
            let categoryJson = {
                domain: localStorage.getItem("domainId"),
                name: element.Category.Value,
                id: element.Category.getKey()
            };
            element.ApiObject.item.category = categoryJson;
            element.ApiObject.product.category = categoryJson;
            element.ApiObject.product.kind = ProductClassApiSend[element.Class.Value as keyof typeof ProductClassApiSend];
            element.ApiObject.item.code = element.Code;
            element.ApiObject.product.code = element.Code;
            element.ApiObject.item.purchasePrice = element.CostPrice;
            element.ApiObject.item.description = element.Description;
            element.ApiObject.product.description = element.Description;
            element.ApiObject.product.retention = element.IRPF.Percentage;
            element.ApiObject.product.vat = element.Tax.getKey();
            element.ApiObject.item.price = element.Price;
            element.ApiObject.item.status = ProductStatusApiSend[element.Status.Value as keyof typeof ProductStatusApiSend];
            element.ApiObject.product.status = ProductStatusApiSend[element.Status.Value as keyof typeof ProductStatusApiSend];
            element.ApiObject.product.type = ProductTypeApiSend[element.Type.Value as keyof typeof ProductTypeApiSend];
            element.ApiObject.item.product = element.ApiObject.product;
            let responseItem = await ApiHttpRequest.post(BASE_URL + PRODUCT_URL.GET_POST_ITEM, {}, element.ApiObject.item);
            let responseProduct = await ApiHttpRequest.post(BASE_URL + PRODUCT_URL.GET_POST_PRODUCT, {}, element.ApiObject.product);
            if(!(responseItem?.type! == 'error') && !(responseProduct?.type! == 'error'))
                return element;
            else
                throw new ErrorResponse('1206', 'Product');
        }
            throw new ErrorResponse('1206', 'Product');
    }
}

let generateFilters = (filter: ProductFilter) => {
    let params = {};
    if(filter?.fields?.has(ProductFields.GLOBAL))
        Object.defineProperty(params, ProductFields.GLOBAL, {
            value: filter?.fields?.get(ProductFields.GLOBAL),
            enumerable: true
        })
    if(filter?.fields?.has(ProductFields.CLASS))
        Object.defineProperty(params, ProductFields.CLASS, {
            value: filter?.fields?.get(ProductFields.CLASS),
            enumerable: true
        })
    Object.defineProperty(params, 'per_page', {
        value: filter?.pageItems ? filter.pageItems : 10,
        enumerable : true,
    })
    Object.defineProperty(params, 'page', {
        value: filter?.pageNum ? filter.pageNum : 1,
        enumerable : true,
    })
    let checkOrder = (filter: IFilter<any, any, any>, invoiceOrder: ProductOrder, order: IOrderFilter): IOrderFilter => {
        if(filter && filter.orderBy && filter.orderBy.has(invoiceOrder)){
            order.orderBy += order.orderBy.length == 0 ? invoiceOrder : ";" + invoiceOrder;
            order.order += order.order.length == 0 ? filter.orderBy.get(invoiceOrder) : ";" + filter.orderBy.get(invoiceOrder);
        }
        return order;
    }
    if(filter && filter.orderBy && filter.orderBy.size > 0){
        let order: IOrderFilter = {
            orderBy: '',
            order: ''
        }
        order = checkOrder(filter, ProductOrder.CATEGORY, order);
        order = checkOrder(filter, ProductOrder.CODE, order);
        order = checkOrder(filter, ProductOrder.NAME, order);
        if(order.orderBy.length > 0){
            Object.defineProperty(params, 'orderBy', {
                value: order.orderBy,
                enumerable : true,
            })
            Object.defineProperty(params, 'order', {
                value: order.order,
                enumerable : true,
            })
        }
    }
    return params;
}