import { ICertificateSpecificMethods } from "../interfaces/serviceInterfaces";
import { ErrorResponse, Response } from "../utils/Response";
import { ICollection, IResponse } from "../interfaces/utilitiesInterfaces";
import { ICertificate } from "../aon";
import { ICertificateSharedType, ICertificateType } from "../interfaces/modelsInterfaces";
import { Certificate } from "../models/Certificate";
import { ICertificateSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { GenericService } from "./GenericCrudService";

export class CertificateService extends GenericService<Certificate, never> implements ICertificateSpecificMethods {
    protected SpecificMethodsRepository: ICertificateSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Certificate> & ISingleObjectCrudRepository<Certificate>, type: { new (): Certificate }, SpecificMethodsRepository: ICertificateSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    async uploadCertificate(file: File, cert: Certificate): Promise<IResponse<ICertificate>> {
        try {
            return new Response<ICertificate>(await this.SpecificMethodsRepository.uploadCertificate(file, cert));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0201');
        }
    }

    async getCertificateTypeList(): Promise<IResponse<ICollection<ICertificateType>>> {
        try {
            return new Response<ICollection<ICertificateType>>(await this.SpecificMethodsRepository.getCertificateTypeList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0201');
        }
    }

    async getCertificateSharedTypeList(): Promise<IResponse<ICollection<ICertificateSharedType>>> {
        try {
            return new Response<ICollection<ICertificateSharedType>>(await this.SpecificMethodsRepository.getCertificateSharedTypeList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0201');
        }
    }

    async validateCertificate(key: string): Promise<IResponse<boolean>> {
        try {
            return new Response<boolean>(await this.SpecificMethodsRepository.validateCertificate(key));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0201');
        }
    }
}