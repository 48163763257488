import { Component, OnInit } from '@angular/core';
import { formatNumber,validateFormatNumber } from 'src/app/core/utilities/number';
import { ErrorResponse, IBank, ICollection } from 'libraries/AonSDK/src/aon';
import { BankService } from 'src/app//core/services/bank.service';
import { DateFormat } from 'src/app/core/utilities/time';

@Component({
  selector    : 'app-card-bank',
  templateUrl : './card-bank.component.html',
  styleUrls   : ['./card-bank.component.scss']
})

export class CardBankComponent implements OnInit {
  spinner     : boolean = true;
  totalAmount : number  = 0;
  banks      !: ICollection<IBank>;

  constructor(
    private bankService: BankService
  ) {
    this.bankService.getBankList().then((response) => {
     this.banks = response;
      response.forEach((bank) => {
        if(typeof bank.Total == 'number')
          this.totalAmount += bank.Total;
      });
      // Quitamos el spinner despues de cargar los bancos
      this.spinner = false;
    });
    // Si no tenemos bancos, quitamos el spinner
    if(!this.hasBanks())
      this.spinner = false;
  }

  ngOnInit(): void {
  }

  hasBanks(): boolean {
    if (!this.banks) {
      return false;
    } else {
      return this.banks && this.banks.size() > 0;
    }
  }

  formatTotal(value: number): string {
    return formatNumber(value);
  }

  currentDate(bank: IBank): string {
    return DateFormat(bank.LastUpdate, 'dd/MM/yyyy') || '';
  }
}
