import { IContractSpecificMethods } from "../interfaces/serviceInterfaces";
import { ICcc, ICno, IContract, IContractType, IDayType, IOccupation, IQuoteGroup, IRlce, ISalaryType, ITaskHolder, IWorkerCollective, IWorkplace } from "../interfaces/modelsInterfaces"
import { Response, ErrorResponse } from "../utils/Response";
import { ICollection, IFilter, IResponse } from "../interfaces/utilitiesInterfaces";
import { IContractSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { GenericService } from "./GenericCrudService";
import { Contract } from "../models/Contract";
import { TaskHolder } from "../models/TaskHolder";
import { ContractFilter } from "../utils/ModelsFilters";

export class ContractService extends GenericService<Contract, ContractFilter> implements IContractSpecificMethods {
    protected SpecificMethodsRepository: IContractSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Contract> & ISingleObjectCrudRepository<Contract>, type: { new (): Contract }, SpecificMethodsRepository: IContractSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    async getTypeContractList(): Promise<IResponse<ICollection<IContractType>>> {
        try {
            return new Response<ICollection<IContractType>>(await this.SpecificMethodsRepository.getTypeContractList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1102');
        }    
    }

    async getOccupationList(): Promise<IResponse<ICollection<IOccupation>>> {
        try {
            return new Response<ICollection<IOccupation>>(await this.SpecificMethodsRepository.getOccupationList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1102');
        }
    }

    async getCnoList(): Promise<IResponse<ICollection<ICno>>> {
        try {
            return new Response<ICollection<ICno>>(await this.SpecificMethodsRepository.getCnoList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1102');
        }
    }

    async getRlceList(): Promise<IResponse<ICollection<IRlce>>> {
        try {
            return new Response<ICollection<IRlce>>(await this.SpecificMethodsRepository.getRlceList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1102');
        }
    }

    async getWorkerCollectiveList(): Promise<IResponse<ICollection<IWorkerCollective>>> {
        try {
            return new Response<ICollection<IWorkerCollective>>(await this.SpecificMethodsRepository.getWorkerCollectiveList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1102');
        }
    }
    
    async createContractRequest(contract: IContract, taskHolder: TaskHolder, note?: string | undefined): Promise<IResponse<IContract>> {
        try {
            return new Response<Contract>(await this.SpecificMethodsRepository.createContractRequest(contract, taskHolder, note));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1102');
        }
    }

    async countContracts(filter?: ContractFilter): Promise<IResponse<number>> {
        try {
            return new Response<number>(await this.SpecificMethodsRepository.countContracts(filter));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1102');
        }
    }
    
    async getQuoteGroupList(): Promise<IResponse<ICollection<IQuoteGroup>>> {
        try {
            return new Response<ICollection<IQuoteGroup>>(await this.SpecificMethodsRepository.getQuoteGroupList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1103');
        }
    }

    async getSalaryTypeList(): Promise<IResponse<ICollection<ISalaryType>>> {
        try {
            return new Response<ICollection<ISalaryType>>(await this.SpecificMethodsRepository.getSalaryTypeList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0101');
        }
    }

    async getDayTypeList(): Promise<IResponse<ICollection<IDayType>>> {
        try {
            return new Response<ICollection<IDayType>>(await this.SpecificMethodsRepository.getDayTypeList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0101');
        }
    }

    async getCccList(): Promise<IResponse<ICollection<ICcc>>> {
        try {
            return new Response<ICollection<ICcc>>(await this.SpecificMethodsRepository.getCccList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0101');
        }
    }

    async getWorkplaceList(): Promise<IResponse<ICollection<IWorkplace>>> {
        try {
            return new Response<ICollection<IWorkplace>>(await this.SpecificMethodsRepository.getWorkplaceList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0101');
        }
    }
}