import { IContact, IPaymentMethod, IInvoiceTransactionType, ContactType, IMedia, IBank, MediaType, IAddress, ContactMediaType, ICountryAon } from "../interfaces/modelsInterfaces";
import { IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";
import { PaymentMethod, PaymentMethodJSON } from "./PaymenMethod";
import { InvoiceTransactionType } from "./InvoiceTransactionType";
import { Collection } from "../utils/Collection";
import { MediaJSON, Media } from "./Media";
import { Bank } from "./Bank";
import { Address, AddressJSON } from "./Address";
import { CountryAon } from "./CountryAon";
import { isBlankAddress, isSameObjectAndAddress } from "../utils/AddressHelper";
import { ContactFilter } from "../utils/ModelsFilters";
import { ContactFields } from "../utils/ModelsFields";

export class Contact implements IContact, IModel {
    private key: string;
    private apiObject: any;
    private name: string;
    private comercialName: string;
    private country: ICountryAon;
    private document: string;
    private address: IAddress;
    private email: Media[];
    private phone: Media[];
    private web: Media[];
    private bankAccount: Bank[];
    private paymentMethod: IPaymentMethod;
    private transactionType: IInvoiceTransactionType;
    private irpf: boolean;
    private re: boolean;
    private type: ContactType;
    private cashBasis: boolean;
    private alfRegister: boolean;

    constructor(name?: string, comercialName?: string, country?: ICountryAon, document?: string, address?: IAddress, email?: Media[], phone?: Media[], web?: Media[], bankAccount?: Bank[], paymentMethod?: IPaymentMethod, transactionType?: IInvoiceTransactionType, irpf?: boolean, re?: boolean, type?: ContactType) {
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
        this.name = name || '';
        this.comercialName = comercialName || '';
        this.country = country || new CountryAon();
        this.document = document || '';
        this.address = address || new Address();
        this.email = email || [];
        this.phone = phone || [];
        this.web = web || [];
        this.bankAccount = bankAccount || [];
        this.paymentMethod = paymentMethod || new PaymentMethod();
        this.transactionType = transactionType || new InvoiceTransactionType();
        this.irpf = irpf || false;
        this.re = re || false;
        this.type = type || ContactType.NULL;
        this.cashBasis = false;
        this.alfRegister = false;
    }

    getName(): string {
        return this.name
    }

    setName(value: string): Contact {
        this.name = value;
        return this
    }

    getComercialName(): string {
        return this.comercialName
    }

    setComercialName(value: string): Contact {
        this.comercialName = value;
        return this
    }

    getCountry(): ICountryAon {
        return this.country
    }

    setCountry(value: ICountryAon): Contact {
        this.country = value;
        return this
    }

    getDocument(): string {
        return this.document
    }

    setDocument(value: string): Contact {
        this.document = value;
        return this
    }

    getAddress(): IAddress {
        return this.address
    }

    setAddress(value: IAddress): Contact {
        this.address = value;
        return this
    }

    getEmail(): Media[] {
        return this.email
    }

    setEmail(value: Media[]): Contact {
        this.email = value;
        return this
    }

    getPhone(): Media[] {
        return this.phone
    }

    setPhone(value: Media[]): Contact {
        this.phone = value;
        return this
    }

    getWeb(): Media[] {
        return this.web
    }

    setWeb(value: Media[]): Contact {
        this.web = value;
        return this
    }

    getBankAccount(): Bank[] {
        return this.bankAccount
    }

    setBankAccount(value: Bank[]): Contact {
        this.bankAccount = value;
        return this
    }

    getPaymentMethod(): IPaymentMethod {
        return this.paymentMethod
    }

    setPaymentMethod(value: IPaymentMethod): Contact {
        this.paymentMethod = value;
        return this
    }

    getTransactionType(): IInvoiceTransactionType {
        return this.transactionType
    }

    setTransactionType(value: IInvoiceTransactionType): Contact {
        this.transactionType = value;
        return this
    }

    getIRPF(): boolean {
        return this.irpf
    }

    setIRPF(value: boolean): Contact {
        this.irpf = value;
        return this
    }

    getRE(): boolean {
        return this.re
    }

    setRE(value: boolean): Contact {
        this.re = value;
        return this
    }

    public get ALFRegister(): boolean {
        return this.alfRegister;
    }

    public set ALFRegister(value: boolean) {
        this.alfRegister = value;
    }

    public get CashBasis(): boolean {
        return this.cashBasis;
    }

    public set CashBasis(value: boolean) {
        this.cashBasis = value;
    }

    public getALFRegister(): boolean {
        return this.alfRegister;
    }

    public setALFRegister(value: boolean): IContact {
        this.alfRegister = value;
        return this;
    }

    public getCashBasis(): boolean {
        return this.cashBasis;
    }

    public setCashBasis(value: boolean): IContact {
        this.cashBasis = value;
        return this;
    }

    public get Type(): ContactType {
        return this.type;
    }

    public set Type(value: ContactType) {
        this.type = value;
    }

    public getType(): ContactType {
        return this.type;
    }

    public setType(value: ContactType): IContact {
        this.type = value;
        return this;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Key() {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    public get ComercialName(): string {
        return this.comercialName;
    }

    public set ComercialName(value: string) {
        this.comercialName = value;
    }

    public get Country(): ICountryAon {
        return this.country;
    }

    public set Country(value: ICountryAon) {
        this.country = value;
    }

    public get Document(): string {
        return this.document;
    }

    public set Document(value: string) {
        this.document = value;
    }

    public get Address(): IAddress {
        return this.address;
    }

    public set Address(value: IAddress) {
        this.address = value;
    }

    public get Email(): Media[] {
        return this.email;
    }

    public set Email(value: Media[]) {
        this.email = value;
    }

    public get Phone(): Media[] {
        return this.phone;
    }

    public set Phone(value: Media[]) {
        this.phone = value;
    }

    public get Web(): Media[] {
        return this.web;
    }

    public set Web(value: Media[]) {
        this.web = value;
    }

    public get BankAccount(): Bank[] {
        return this.bankAccount;
    }

    public set BankAccount(value: Bank[]) {
        this.bankAccount = value;
    }

    public get PaymentMethod(): IPaymentMethod {
        return this.paymentMethod;
    }

    public set PaymentMethod(value: IPaymentMethod) {
        this.paymentMethod = value;
    }

    public get TransactionType(): IInvoiceTransactionType {
        return this.transactionType;
    }

    public set TransactionType(value: IInvoiceTransactionType) {
        this.transactionType = value;
    }

    public get IRPF(): boolean {
        return this.irpf;
    }

    public set IRPF(value: boolean) {
        this.irpf = value;
    }

    public get RE(): boolean {
        return this.re;
    }

    public set RE(value: boolean) {
        this.re = value;
    }

    getKey(): string {
        return this.key;
    }
    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.name);
        map.set('comercialName', this.comercialName);
        map.set('country', this.country);
        map.set('document', this.document);
        map.set('address', this.address);
        map.set('email', this.email);
        map.set('phone', this.phone);
        map.set('web', this.web);
        return map;
    }
    getSortableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.name);
        map.set('comercialName', this.comercialName);
        map.set('country', this.country);
        map.set('document', this.document);
        map.set('address', this.address);
        map.set('email', this.email);
        map.set('phone', this.phone);
        map.set('web', this.web);
        return map;
    }

}

export class ContactJSON {
    static parseDataToSend(data: Contact): any {
        let json = {
            domain: {
                id: localStorage.getItem("domainId"),
                name: localStorage.getItem("domainName")
            },
            document: data.Document,
            documentCountry: data.Country.Value,
            name: data.Name,
            alias: data.ComercialName,
            legalPerson: false,
            confidential: false,
            global: false,
            dirty: true,
            addresses: [{}],
            media: [{}],
            banks: [{}],
            paymethod: {
                domain: localStorage.getItem("domainId"),
                numberOfPymnts: 1,
                daysToFirstPymnt: 0,
                daysBetweenPymnts: 0,
                pymntDays: "",
                paymethod: {
                    id: data.PaymentMethod.getKey()
                },
                dirty: false,
                removed: false
            },
            surcharge: data.RE,
            withholding: data.IRPF,
            withholdingFarmer: data.ALFRegister,
            vatAccrualPayment: data.CashBasis,
            transaction: data.TransactionType.getKey(),
            status: "ACTIVE"
        }
        json.media.pop()
        json.banks.pop()
        data.Email.concat(data.Phone.concat(data.Web)).forEach((element: Media) => {
            let newMedia = {
                media: ContactMediaType[element.Type as keyof typeof ContactMediaType],
                value: element.Value,
                administrative: true,
                commercial: true,
                technical: true,
                dirty: true,
                removed: false
            };
            json.media.push(newMedia);
        });
        data.BankAccount.forEach((element: IBank) => {
            let newBank = {
                domain: localStorage.getItem("domainId"),
                bankAccount: {
                    iban: element.Iban,
                    bank: "",
                    bankCode: "",
                    bic: ""
                },
                bank_account: element.Iban,
                iban: element.Iban,
                bank: "",
                bic: "",
                sufix: "",
                alias: "",
                active: true,
                account: {
                    domain: localStorage.getItem("domainId"),
                    code: "",
                    description: "",
                    alias: ""
                },
                dirty: true,
                removed: false,
                fullName: element.Iban + " - "
            };
            json.banks.push(newBank);
        });
        if(!isBlankAddress(data.Address)){
            json.addresses = [{
                main: true,
                streetType : data.Address.Type.Value,
                address : data.Address.Direction,
                address2 : data.Address.RestOfDirection,
                city : data.Address.City,
                number : data.Address.Number,
                province : data.Address.Province,
                zip : data.Address.PostalCode,
                country: data.Address.Country.Value,
                dirty: true,
                removed: false
            }];
        }else{
            json.addresses = [];
        }
        return json
    }

    static parseDataToReceive(data: any, type: ContactType): Contact {
        let contact = new Contact();
        contact.ApiObject = data;
        // parece que usan un array de direcciones, cogemos la ultima edicion introducida para el usuario.
        // seguramente el resto de direcciones se usa para las facturas ya generadas a este contacto 
        let newAddress: Address = new Address();
        if(data.addresses && data.addresses.length > 0){
            data.addresses.forEach((element:any) => {
                if(newAddress.ApiObject && newAddress.ApiObject.id ){
                    if((newAddress.ApiObject.id < element.id)){
                        newAddress = AddressJSON.parseDataToReceive(element);
                    }
                } else {
                    newAddress = AddressJSON.parseDataToReceive(element);
                }
            });
        }
        contact.Address = newAddress;
        //(data.addresses[data.addresses.length-1]) ? AddressJSON.parseDataToReceive(data.addresses[data.addresses.length-1]) : new Address();
        if(data.banks)
            data.banks.forEach((element: any) => {
                let bank = new Bank().setIban(element.bank_account);
                bank.ApiObject = element;
                bank.Key = element.id;
                contact.BankAccount.push(bank);
            });
        contact.ComercialName = data.alias;
        contact.Country = new CountryAon().setValue(data.nationality);
        contact.Document = data.document;
        data.media.forEach((element: any) => {
            if(element.media == MediaType.EMAIL)
                contact.Email.push(MediaJSON.parseDataToReceive(element));
            else if(element.media == MediaType.PHONE || element.media == 'cellular')
                contact.Phone.push(MediaJSON.parseDataToReceive(element));
            else if(element.media == MediaType.WEB)
                contact.Web.push(MediaJSON.parseDataToReceive(element));
        });
        contact.Key = data.id + ";" + type;
        contact.Name = data.name;
        contact.PaymentMethod = PaymentMethodJSON.parseDataToReceive(data.paymethod.paymethod);
        let transaction = new InvoiceTransactionType();
        transaction.Key = data.transaction;
        contact.TransactionType = transaction;
        contact.Type = type;
        contact.IRPF = data.withholding ? data.withholding : false;
        contact.RE = data.surcharge ? data.surcharge : false;
        contact.ALFRegister = data.withholdingFarmer ? data.withholdingFarmer : false;
        contact.CashBasis = data.vatAccrualPayment ? data.vatAccrualPayment : false;
        return contact;
    }

    static parseDataToReceiveFullAddress(data: any): Collection<Address> {
        let addressCollection = new Collection<Address>();
        if(data.addresses && data.addresses.length > 0)
            data.addresses.forEach((element: any) => {
                addressCollection.add(AddressJSON.parseDataToReceive(element));
            })
        return addressCollection;
    }

    static parseDataToReceiveList(data: any, filter: ContactFilter): Contact {
        let dataType = filter?.fields?.get(ContactFields.TYPE)[0] ? filter?.fields?.get(ContactFields.TYPE)[0] : ContactType.NULL;
        let contact = new Contact();
        contact.ApiObject = data;
        contact.Name = data.name;
        contact.Document = data.document;
        contact.Key = data.id + ";" + (dataType == ContactType.CLIENTE ? ContactType.CLIENTE : data.type);
        if(data.registryAddresses && data.registryAddresses.length > 0){
            contact.Address = data.registryAddresses.length > 0 ? AddressJSON.parseDataToReceive(data.registryAddresses[data.registryAddresses.length-1]) : new Address();
            data.registryAddresses.forEach((element: any) => {
                if(element.country){
                    contact.Country = new CountryAon().setValue(element.country);
                }
            })
        }
        if(data.media && data.media.length > 0){
            let phone = new Media();
            data.media.forEach((element: any) => {
                if(element.media == MediaType.PHONE || element.media == 'cellular')
                    phone = MediaJSON.parseDataToReceive(element);
            });
            contact.Phone.push(phone);
        }
        return contact;
    }

    static parseDataToUpdate(contact: Contact): any {
        let json = contact.ApiObject;
        json.surcharge = contact.RE,
        json.withholding = contact.IRPF,
        json.withholdingFarmer = contact.ALFRegister,
        json.vatAccrualPayment = contact.CashBasis,
        json.transaction = contact.TransactionType.getKey(),
        json.paymethod.paymethod = {
            id: contact.PaymentMethod.getKey()
        },
        json.paymethod.dirty = true
        if(!isBlankAddress(contact.Address)){
            if(json.addresses.length > 0 && !isSameObjectAndAddress(json.addresses[json.addresses.length -1], contact.Address)){
                json.addresses.push({
                    streetType : contact.Address.Type.Value,
                    address : contact.Address.Direction,
                    address2 : contact.Address.RestOfDirection,
                    city : contact.Address.City,
                    number : contact.Address.Number,
                    province : contact.Address.Province,
                    zip : contact.Address.PostalCode,
                    country: contact.Address.Country.Value,
                    dirty: true,
                    removed: false
                });
            } else if(json.addresses.length == 0){
                json.addresses.push({
                    main: true,
                    streetType : contact.Address.Type.Value,
                    address : contact.Address.Direction,
                    address2 : contact.Address.RestOfDirection,
                    city : contact.Address.City,
                    number : contact.Address.Number,
                    province : contact.Address.Province,
                    zip : contact.Address.PostalCode,
                    country: contact.Address.Country.Value,
                    dirty: true,
                    removed: false
                });
            }
        }
        // json.addresses[0] = {
            // main: true,
            // streetType : contact.Address.Type.Value,
            // address : contact.Address.Direction,
            // address2 : contact.Address.RestOfDirection,
            // city : contact.Address.City,
            // number : contact.Address.Number,
            // province : contact.Address.Province,
            // zip : contact.Address.PostalCode,
            // country: contact.Address.Country.Value,
            // dirty: true,
            // removed: false
        // }
        json.document = contact.Document
        json.documentCountry = contact.Country.Value
        json.name = contact.Name
        json.alias = contact.ComercialName
        let allMedia = contact.Phone.concat(contact.Email.concat(contact.Web));
        allMedia.forEach((element: Media) => {
            if(!element.ApiObject.id){
                let newMedia = {
                    media: ContactMediaType[element.Type as keyof typeof ContactMediaType],
                    value: element.Value,
                    administrative: true,
                    commercial: true,
                    technical: true,
                    dirty: true,
                    removed: false
                };
                json.media.push(newMedia);
            }
        });
        json.media.forEach((element: any) => {
            element.removed = true
            allMedia.forEach((media: IMedia) => {
                if(!element.id){
                    element.removed = false;
                }
                if(element.media.toUpperCase() == ContactMediaType[media.Type as keyof typeof ContactMediaType]
                && element.id && element.id == media.getKey() && media.Value != ''){
                    element.value = media.Value
                    element.dirty = true;
                    element.removed = false;
                }                
            })
            if(element.value == ''){
                element.removed = true;
                element.dirty = false;
            }
        });
        contact.BankAccount.forEach((bank: Bank) => {
            if(!bank.ApiObject.id){
                let newBank = {
                    domain: localStorage.getItem("domainId"),
                    bankAccount: {
                        iban: bank.Iban,
                        bank: "",
                        bankCode: "",
                        bic: ""
                    },
                    bank_account: bank.Iban,
                    iban: bank.Iban,
                    bank: "",
                    bic: "",
                    sufix: "",
                    alias: "",
                    active: true,
                    account: {
                        domain: localStorage.getItem("domainId"),
                        code: "",
                        description: "",
                        alias: ""
                    },
                    dirty: true,
                    removed: false,
                    fullName: bank.Iban + " - "
                };
                json.banks.push(newBank);
            }
        });
        json.banks.forEach((element: any) => {
            if(element.id) element.removed = true
            contact.BankAccount.forEach((bank: Bank) => {
                if(element.id == bank.getKey()){
                    element.bank_account = bank.Iban
                    element.iban = bank.Iban
                    element.fullName = bank.Iban + " - "
                    element.dirty = true
                    element.removed = false
                }
            })
        });
        return json;
    }

}