import { ICcc } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class Ccc implements ICcc, IModel  {
    private key: string;
    private apiObject: any;
    private description: string;
    private code: string;

    constructor(){
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
        this.code = '';
        this.description = '';
    }
    
    public get Description(): string {
        return this.description;
    }

    public set Description(value: string) {
        this.description = value;
    }
    
    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string): ICcc {
        this.description = value;
        return this;
    }

    public get Code(): string {
        return this.code;
    }

    public set Code(value: string) {
        this.code = value;
    }
    
    public getCode(): string {
        return this.code;
    }

    public setCode(value: string): ICcc {
        this.code = value;
        return this;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    getKey(): string {
        return this.Key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
    
    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class CccJSON {
    static parseDataToReceive(element: any): ICcc {
        let ccc = new Ccc();
        ccc.ApiObject = element;
        ccc.Key = element.id ? element.id : '';
        ccc.Code = element.code ? element.code : '';
        ccc.Description = element.description ? element.description : '';
        return ccc;
    }
}