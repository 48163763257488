import { IDomainSpecificMethodsRepository } from "../interfaces/repositoryInterfaces";
import { DOMAINS } from "../utils/Environment";


export class ApiDomainSpecificMethodsRepository implements IDomainSpecificMethodsRepository {
    getLogo(): string {
        if(DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS] == undefined){
            return './assets/images/logo/logo-aon.png';
        } else {
            let domain = DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS];
            return domain.logo;
        }
    }

    getFavicon(): string {
        if(DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS] == undefined){
            return 'favicon.ico';
        } else {
            let domain = DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS];
            return domain.favicon;
        }
    }

    getServiceDesk(): string {
        if(DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS] == undefined){
            return '';
        } else {
            let domain = DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS];
            return domain.servicedesk;
        }
    }

    getTitle(): string {
        if(DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS] == undefined){
            return 'Aon Solutions';
        } else {
            let domain = DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS];
            return domain.title;
        }
    }

    getLoginMessage(): string {
        if(DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS] == undefined){
            return 'LOGIN.ACCESS_YOUR_AON_ACCOUNT';
        } else {
            let domain = DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS];
            return domain.loginMessage;
        }
    }
}