import { Component, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { AonPermission } from 'libraries/AonSDK/src/aon';
import { predefinedRoles } from 'src/app/app.globals';
import { EnterpriseService } from 'src/app/core/services/enterprise.service';
import { MenuItem } from 'src/app/core/models/interface/menu-item';
import { DropdownMenuComponent } from '../../components/dropdown-menu/dropdown-menu.component';
import { AppComponent } from '../../../app.component';
import { DomainService } from 'src/app/core/services/domain.service';

@Component({
  selector    : 'app-topbar',
  templateUrl : './top-bar.component.html',
  styleUrls   : ['./top-bar.component.scss'],
  host        : {
    '[style.width]' : "'100%'",
    '[style.height]': "'100%'",
  },
})

export class TopBarComponent implements OnInit, OnChanges {
  @ViewChild('first') dropdownMenuComponent: DropdownMenuComponent = new DropdownMenuComponent;
  menuItem          : MenuItem [] = []
  displayHomeIcon   : boolean     = false;
  usserLoggged      : boolean     = this.auth.isLoggedIn();
  enterpriseSelected: boolean     = false;

  constructor(
    private router            : Router,
    private auth              : AuthService,
    private enterpriseService : EnterpriseService,
    private translateService  : TranslateService,
    private appComponent      : AppComponent,
    public  domainService     : DomainService
  ){
    // Comprobar si tenemos una empresa seleccionada
    if(this.usserLoggged){
      this.enterpriseService.existEntepriseData().then((data) => {
        this.enterpriseSelected = data;
      });
    }

    if(this.usserLoggged){
      // Si estamos logueados
      this.auth.getUserRol().then((permissions) => {
        // La ruta de editar perfil a la que vamos, dependiendo del tipo (aunque el componente sea el mismo)
        let routerEditProfile: string = '';
        if(permissions.includes(predefinedRoles.ADMIN) || permissions.includes(predefinedRoles.ENTERPRISE)){
          routerEditProfile = 'edit-profile';
        } else if(permissions.includes(predefinedRoles.EMPLOYEE)){
          routerEditProfile = 'edit-profile-employee';
        }
        // Opciopnes del menu de perfil
        this.translateService.get(
          ['HEADER.EDIT_PROFILE','HEADER.HELP','HEADER.LOGOUT', 'HEADER.LANGUAGE', 'LANGUAGE.ENGLISH', 'LANGUAGE.SPANISH']
        ).subscribe( result => {
          this.menuItem = [
            {root:true, text: result['HEADER.EDIT_PROFILE'], icon:'person_pin' , colorIcon:'black', routerlink: routerEditProfile},
            {root:true, text: result['HEADER.LANGUAGE'], icon:'language',  colorIcon:'black', children:[
              {root:false, text: result['LANGUAGE.SPANISH'], click:() => this.selectLanguage('es')},
              {root:false, text: result['LANGUAGE.ENGLISH'], click:() => this.selectLanguage('en')}
            ]},
            {root:true, text: result['HEADER.LOGOUT'], icon:'exit_to_app', colorIcon:'black', click:() => this.logout()},
          ];
        });
      });
    } else {
      // Si no estamos logueados
      this.translateService.get(
        ['LANGUAGE.ENGLISH', 'LANGUAGE.SPANISH']
      ).subscribe( result => {
        this.menuItem = [
          {root:true, text: result['LANGUAGE.SPANISH'], click:() => this.selectLanguage('es')},
          {root:true, text: result['LANGUAGE.ENGLISH'], click:() => this.selectLanguage('en')}
        ];
      });
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      const contentContainer        = document.querySelector('.mat-sidenav-content')  || window;
      const contentLayoutContainer  = document.querySelector('#layaut-componet-body') || window;
      contentContainer.scrollTo(0, 0);
      contentLayoutContainer.scrollTo(0, 0);

      event instanceof NavigationEnd ? this.checkCurrentRoute() : null
    });
  }

  checkCurrentRoute() {
    // Si esta en los dos home que tenemos, no muestre el icono de volver a la home en ellos
    this.displayHomeIcon = this.router.url != '/home' && this.router.url != '/main' ? true : false;
  }

  ngOnChanges(): void {
  }

  logout() {
    this.auth.logout();
  }

  selectLanguage(language: string) {
    this.appComponent.selectLanguage(language)
  }

}
