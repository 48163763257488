import { ICollection } from "../interfaces/utilitiesInterfaces";
import { IMarkDetail, IMark, IMarkHistory, ILocation, MarkStatus } from "../interfaces/modelsInterfaces";
import { IMarkDetailSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { MarkDetailJSON, MarkDetail } from "../models/MarkDetail";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { ErrorResponse } from "../utils/Response";
import { formatDate } from "../utils/Date";
import { MarkHistoryJSON } from "../models/MarkHistory";
import { LocationJSON } from "../models/Location";
import { MARK_URL, MESSAGE_URL } from "../utils/ApiUrls";
import { GenericRepository } from "./GenericRepository";

export class MarkDetailRepository extends GenericRepository<MarkDetail> implements IMarkDetailSpecificMethodsRepository, IMultipleObjectCrudRepository<MarkDetail>, ISingleObjectCrudRepository<MarkDetail> {
    async getLocationList(): Promise<ICollection<ILocation>> {
        let collection = new Collection<ILocation>();
        let response = await ApiHttpRequest.get(BASE_URL + MARK_URL.LOCATION, {}, {});
        if(!(response?.type! == 'error'))
            response.forEach((element: any) => {
                collection.add(LocationJSON.parseDataToReceive(element));
            });
        else
            throw new ErrorResponse('1501');
        return collection;
    }

    async getMarkDetailList(mark: IMark): Promise<ICollection<IMarkDetail>> {
        let collection = new Collection<IMarkDetail>();
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + MARK_URL.LIST_HOLDER_DETAIL, generateFilters(mark)), {}, {});
        if(!(response?.type! == 'error'))
            response.forEach((element: any) => {
                collection.add(MarkDetailJSON.parseDataToReceive(element));
            });
        else
            throw new ErrorResponse('1502');
        return collection;
    }

    async getMarkDetailHistory(key: String): Promise<ICollection<IMarkHistory>> {
        let collection = new Collection<IMarkHistory>();
        let params = { id: key };
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + MARK_URL.HISTORIC, params), {}, {});
        if(!(response?.type! == 'error'))
            response.forEach((element: any) => {
                collection.add(MarkHistoryJSON.parseDataToReceive(element));
            });
        else
            throw new ErrorResponse('1503');
        let array = collection.toArray();
        array.shift();
        let newCollection = new Collection<IMarkHistory>();
        newCollection.copyArrayToCollection(array);
        return newCollection;
    }

    async markEntry(): Promise<boolean> {
        let params = {
            parent: false,
            status: "in",
            task_holder: localStorage.getItem('registry'),
        }
        let geoLocation = await getCoords();
        if(geoLocation != "")
            Object.defineProperty(params, 'coordinates', {
                value: geoLocation,
                enumerable: true
            })
        let response = await ApiHttpRequest.post(BASE_URL + MARK_URL.TIMECONTROL, {}, params);
        if(!(response?.type! == 'error'))
            return true;
        else
            throw new ErrorResponse('1504', 'MarkDetail');
    }

    async markPause(): Promise<boolean> {
        let params = {
            parent: false,
            status: "pause",
            task_holder: localStorage.getItem('registry'),
        }
        let geoLocation = await getCoords();
        if(geoLocation != "")
            Object.defineProperty(params, 'coordinates', {
                value: geoLocation,
                enumerable: true
            })
        let response = await ApiHttpRequest.post(BASE_URL + MARK_URL.TIMECONTROL, {}, params);
        if(!(response?.type! == 'error'))
            return true;
        else
            throw new ErrorResponse('1505', 'MarkDetail');
    }

    async markExit(): Promise<boolean> {
        let params = {
            parent: false,
            status: "out",
            task_holder: localStorage.getItem('registry'),
        }
        let geoLocation = await getCoords();
        if(geoLocation != "")
            Object.defineProperty(params, 'coordinates', {
                value: geoLocation,
                enumerable: true
            })
        let response = await ApiHttpRequest.post(BASE_URL + MARK_URL.TIMECONTROL, {}, params);
        if(!(response?.type! == 'error'))
            return true;
        else
            throw new ErrorResponse('1506', 'MarkDetail');
    }

    async deleteMarkDetail(element: IMarkDetail): Promise<boolean> {
        let taskHolder = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + element.IdUser, {}, {})
        if(taskHolder.id.toString() != element.IdUser)
            throw new ErrorResponse('0123', 'MarkDetail');
        let newStatus;
        switch(element.Status){
            case MarkStatus.ENTRADA:
                newStatus = "in";
                break;
            case MarkStatus.SALIDA:
                newStatus = "out";
                break;
            case MarkStatus.PAUSA:
                newStatus = "pause";
                break;
            default:
                break;
        }
        let json = {
            coordinates: element.Location.Coordinates,
            date: element.Date.getTime(),
            location: element.Location.getKey(),
            name: taskHolder.name,
            id: element.getKey(),
            status: newStatus,
            task_holder: element.IdUser,
            time: element.Date.getHours() + ":" + element.Date.getMinutes()
        }
        let response = await ApiHttpRequest.delete(BASE_URL + MARK_URL.TIMECONTROL, {}, json);
        if(response.type == 'error')
            throw new ErrorResponse('1507');
        else
            return true;
    }

    async get(key: string): Promise<MarkDetail> {
        throw new Error("Method not implemented.");
    }

    async create(element: IMarkDetail): Promise<MarkDetail> {
        let taskHolder = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + element.IdUser, {}, {})
        if(taskHolder.id.toString() != element.IdUser)
            throw new ErrorResponse('0123', 'MarkDetail');
        let newStatus;
        switch(element.Status){
            case MarkStatus.ENTRADA:
                newStatus = "in";
                break;
            case MarkStatus.SALIDA:
                newStatus = "out";
                break;
            case MarkStatus.PAUSA:
                newStatus = "pause";
                break;
            default:
                break;
        }
        let json = {
            coordinates: element.Location.Coordinates,
            date: element.Date.getTime(),
            location: element.Location.getKey(),
            name: taskHolder.name,
            status: newStatus,
            task_holder: element.IdUser,
            time: element.Date.getHours() + ":" + element.Date.getMinutes()
        }
        let response = await ApiHttpRequest.post(BASE_URL + MARK_URL.SAVE, {}, json);
        if(response.type == 'error')
            throw new ErrorResponse('1508', 'MarkDetail');
        else
            return MarkDetailJSON.parseDataToReceive(response);
    }

    async update(element: IMarkDetail): Promise<MarkDetail> {
        let taskHolder = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + element.IdUser, {}, {})
        if(taskHolder.id.toString() != element.IdUser)
            throw new ErrorResponse('0123', 'MarkDetail');
        let newStatus;
        switch(element.Status){
            case MarkStatus.ENTRADA:
                newStatus = "in";
                break;
            case MarkStatus.SALIDA:
                newStatus = "out";
                break;
            case MarkStatus.PAUSA:
                newStatus = "pause";
                break;
            default:
                break;
        }
        let json = {
            coordinates: element.Location.Coordinates,
            date: element.Date.getTime(),
            location: element.Location.getKey(),
            name: taskHolder.name,
            id: element.getKey(),
            status: newStatus,
            task_holder: element.IdUser,
            time: element.Date.getHours() + ":" + element.Date.getMinutes()
        }
        let response = await ApiHttpRequest.post(BASE_URL + MARK_URL.SAVE, {}, json);
        if(response.type == 'error')
            throw new ErrorResponse('1509', 'MarkDetail');
        else
            return MarkDetailJSON.parseDataToReceive(response);
    }
}

const getCoords = async () => {
    const options = {
        enableHighAccuracy: false
    }
    const pos: any = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, resolve, options);
    });
    if(pos.coords)
        return pos.coords.latitude + "," + pos.coords.longitude;
    else
        return ""
};

let generateFilters = (mark: IMark) => {
    let date;
    if(mark.Date.getTime() == new Date(0).getTime())
        date = new Date();
    else
        date = mark.Date;
    return {
        taskHolderId: mark.IdUser,
        group: 'DAY',
        startDate: formatDate(date),
        endDate: formatDate(date),
        active: true,
        period: 'today',
    }
}
