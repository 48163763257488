import { ContractJSON, Contract } from "../models/Contract";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { ICollection, IFilter } from "../interfaces/utilitiesInterfaces";
import { ErrorResponse } from "../utils/Response";
import { IContractSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IContract } from "../aon";
import { TaskHolder } from "../models/TaskHolder";
import { CONTRACT_URL, MESSAGE_URL } from "../utils/ApiUrls";
import { formatDate } from "../utils/Date";
import { ISalaryType, IQuoteGroup, IDayType, IWorkplace, ICcc, ICno, IContractType, IOccupation, IRlce, IWorkerCollective } from "../interfaces/modelsInterfaces";
import { collectionDayType } from "../models/DayType";
import { collectionSalaryType } from "../models/SalaryType";
import { QuoteGroupJSON } from "../models/QuoteGroup";
import { ContractFields } from "../utils/ModelsFields";
import { ContractFilter } from "../utils/ModelsFilters";
import { CccJSON } from "../models/Ccc";
import { WorkplaceJSON } from "../models/Workplace";
import { OccupationJSON } from "../models/Occupation";
import { CnoJSON } from "../models/Cno";
import { WorkerCollectiveJSON, getWorkersCollective } from "../models/WorkerCollective";
import { ContractTypeJSON } from "../models/ContractType";
import { RlceJSON } from "../models/Rlce";

export class ContractRepository extends GenericRepository<Contract> implements IContractSpecificMethodsRepository, IMultipleObjectCrudRepository<Contract>, ISingleObjectCrudRepository<Contract> {
    
    async getCollection(filter?: ContractFilter): Promise<ICollection<Contract>> {
        let collection = new Collection<Contract>();
        let active = filter?.fields?.get(ContractFields.STATUS) ? filter?.fields?.get(ContractFields.STATUS) : true;
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + CONTRACT_URL.CONTRACT_LIST, generateFilter(filter)), {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(ContractJSON.parseDataToReceive(element, active));
            });
        }else {
            throw new ErrorResponse('1102');
        }
        return collection;
    }
    
    async get(key: string): Promise<Contract> {
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.ONE_CONTRACT + '?contractId=' + key, {}, {});
        if(!(response?.type! == 'error')){
            return ContractJSON.parseDataToReceiveOneContract(response);
        } else {
            throw new ErrorResponse('1103');
        }
    }

    async countContracts(filter?: ContractFilter): Promise<number> {
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + CONTRACT_URL.CONTRACT_LIST_COUNT, generateFilter(filter)), {}, {});
        if(!(response?.type! == 'error')){
            return response;
        }
        else
            throw new ErrorResponse('1103');
    }

    async createContractRequest(contract: IContract, _taskHolder: TaskHolder, note?: string): Promise<IContract> {
        let cauInfo = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_CAU, {}, {})
        let sender = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + localStorage.getItem('registry'), {}, {})
        let taskHolder = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + _taskHolder.Key, {}, {})
        if(_taskHolder.Key != taskHolder.id)
            throw new ErrorResponse('1104');
        let domain = {
            id: localStorage.getItem('domainId'),
            name: localStorage.getItem('domainName')
        }
        let workflow = {
            comment: "",
            domain: localStorage.getItem('domainId'),
            task_holder: sender,
            type: "opened",
            email: cauInfo.auth.email || ''
        }
        let description = {
            observation: note || '',
            cauinfo: cauInfo,
            fullTimeCheck: contract.Fulltime ? 'true' : 'false',
            salaryCheck: contract.SalaryAccordingAgreement ? 'true' : 'false',
            durationCheck: contract.Undefined ? 'true' : 'false',
            ipf: contract.Document,
            nss: contract.Nss,
            name: contract.Name,
            surname: contract.FirstSurname,
            lastSurname: contract.SecondSurname,
            fra: formatDate(contract.StartDate),
            category: contract.Category,
            gc: contract.QuoteGroup.getKey(),
            ctaCti: contract.Ccc.getCode()
            // ctaCti:, ----------------
            // regime:, ----------------
        }
        if(!contract.Undefined && contract.EndDate){
            Object.defineProperty(description, 'frb', {
                value: formatDate(contract.EndDate),
                enumerable: true
            })
        }
        if(!contract.SalaryAccordingAgreement){
            Object.defineProperty(description, 'salary', {
                value: contract.Salary,
                enumerable: true
            })
            Object.defineProperty(description, 'salaryType', {
                value: contract.SalaryType.getKey(),
                enumerable: true
            })
        }
        if(!contract.Fulltime) {
            Object.defineProperty(description, 'hour', {
                value: contract.Hours,
                enumerable: true
            })
            Object.defineProperty(description, 'jornadaType', {
                value: contract.DayType.getKey(),
                enumerable: true
            })
            if(contract.Coef != 0)
                Object.defineProperty(description, 'coef', {
                    value: contract.Coef,
                    enumerable: true
                })
        }
        let json = {
            domain: domain,
            sender: sender,
            task_holder: taskHolder,
            workgroup: {
                active: true,
                description: "",
                dirty: false,
                domain: localStorage.getItem('domainId'),
                removed: false
            },
            title: "Alta de empleado",
            registry: {},
            workflow: [workflow],
            description: JSON.stringify(description),
            status: 'pending',
            source: 'request',
            source_id: 2,
            files: [],
            project: {},
            tags: [],
            childs: [],
            domaintmp: domain,
            workflowtmp: workflow,
            mytaskholder: sender,
            auth: cauInfo.auth,
            domaincompany: cauInfo.company.domain
        }
        let response = await ApiHttpRequest.post(BASE_URL + CONTRACT_URL.CONTRACT_REQUEST, {}, json)
        if(!(response?.type! == 'error')){
            return contract;
        } else { 
            throw new ErrorResponse('1102');
        }
    }

    async getSalaryTypeList(): Promise<ICollection<ISalaryType>> {
        return collectionSalaryType;
    }

    async getQuoteGroupList(): Promise<ICollection<IQuoteGroup>> {
        let collection = new Collection<IQuoteGroup>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.QUOTE_GROUP, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(QuoteGroupJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getDayTypeList(): Promise<ICollection<IDayType>> {
        return collectionDayType;
    }

    async getWorkplaceList(): Promise<ICollection<IWorkplace>> {
        let collection = new Collection<IWorkplace>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.WORKPLACE_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(WorkplaceJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getCccList(): Promise<ICollection<ICcc>> {
        let collection = new Collection<ICcc>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.CCC_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(CccJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getTypeContractList(): Promise<ICollection<IContractType>> {
        let collection = new Collection<IContractType>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.CONTRACT_TYPE_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(ContractTypeJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getOccupationList(): Promise<ICollection<IOccupation>> {
        let collection = new Collection<IOccupation>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.OCCUPATION_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(OccupationJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getCnoList(): Promise<ICollection<ICno>> {
        let collection = new Collection<ICno>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.CNO_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(CnoJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getRlceList(): Promise<ICollection<IRlce>> {
        let collection = new Collection<IRlce>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.RLCE_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(RlceJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getWorkerCollectiveList(): Promise<ICollection<IWorkerCollective>> {
        let collection = new Collection<IWorkerCollective>();
        getWorkersCollective.forEach((element: any) => {
            collection.add(WorkerCollectiveJSON.parseDataToReceive(element));
        })
        return collection;
    }

}

let generateFilter = (filter?: ContractFilter) => {
    let page = filter?.pageNum ? filter?.pageNum : 1;
    let perPage = filter?.pageItems ? filter?.pageItems : 20;
    let status = filter?.fields?.get(ContractFields.STATUS) ? filter?.fields?.get(ContractFields.STATUS) : undefined;
    let from = filter?.fields?.get(ContractFields.START_DATE) ? filter?.fields?.get(ContractFields.START_DATE) : '';
    let to = filter?.fields?.get(ContractFields.END_DATE) ? filter?.fields?.get(ContractFields.END_DATE) : '';
    let name = filter?.fields?.get(ContractFields.NAME) ? filter?.fields?.get(ContractFields.NAME) : '';
    let workplace = filter?.fields?.get(ContractFields.WORKPLACE) ? filter?.fields?.get(ContractFields.WORKPLACE) : '';
    let ccc = filter?.fields?.get(ContractFields.CCC) ? filter?.fields?.get(ContractFields.CCC) : '';
    let global = filter?.fields?.get(ContractFields.GLOBAL) ? filter?.fields?.get(ContractFields.GLOBAL) : '';
    let json = {
        page: page,
        perPage: perPage,
    };
    if(status != undefined){
        Object.defineProperty(json, 'status', {
            value: status,
            enumerable: true
        });
    }
    if(from != '')
        Object.defineProperty(json, 'from', {
            value: new Date(from).toISOString(),
            enumerable: true
        });
    if(to != '')
        Object.defineProperty(json, 'to', {
            value: new Date(to).toISOString(),
            enumerable: true
        });
    if(name != '')
        Object.defineProperty(json, 'name', {
            value: name,
            enumerable: true
        });
    if(workplace != '')
        Object.defineProperty(json, 'workplace', {
            value: workplace,
            enumerable: true
        });
    if(ccc != '')
        Object.defineProperty(json, 'code', {
            value: ccc,
            enumerable: true
        });
    if(global != '')
        Object.defineProperty(json, 'global', {
            value: global,
            enumerable: true
        });
    return json;
}