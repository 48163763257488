<app-card class="dashboard banks-card min blue">
  <div header>
    <div class="line"></div>
    <div class="text">{{ 'HOME.YOUR_BANKS'| translate }}</div>
    <div class="options">
      <app-icon
        [shape]="'add'"
        [matTooltip]="'BILLING.ADD_BANK' | translate"
        [routerLink]="'/management/treasury/banks'"
      ></app-icon>
    </div>
  </div>
  <div
    body [class.no-banks]="!hasBanks()"
  >
    <app-spinner *ngIf="spinner"></app-spinner>
    <div
      *ngIf="!spinner && hasBanks()"
      class="list-banks"
    >
      <div *ngFor="let bank of banks" class="list-bank-row">
        <div class="list-tesoreria">
          <div class="list-bank-name">
            {{ bank.Name }}
            <!-- {{ bank.Logo }} -->
          </div>
          <div class="list-bank-money">{{ formatTotal(bank.Total) }} €</div>
        </div>
        <div class="date-tesoreria" *ngIf="currentDate(bank) !== ''">
          <span>{{ "BILLING.LAST_UPDATE" | translate }}</span>
          <span>{{ currentDate(bank) }}</span>
        </div>
      </div>
    </div>
    <div
      class="bank-create"
      *ngIf="!hasBanks()"
      [routerLink]="'/billing/banks/create'"
    >
      <div class="title">
        {{ 'HOME.CREATE_YOUR_FIRST_BANK'|translate }}
      </div>
      <div class="banks-create-text">
        {{ 'HOME.CONNECT_AON'|translate }}
      </div>
    </div>
  </div>
  <div
    *ngIf="!spinner && hasBanks()"
    footer
    class="list-bank-footer"
  >
    {{  formatTotal(totalAmount) }} €
  </div>
</app-card>

