import {  Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import { PaginationDate } from 'src/app/core/models/interface/paginationDate';

@Component({
  selector    : 'app-table',
  templateUrl : './table.component.html',
  styleUrls   : ['./table.component.scss']
})

export class TableComponent implements OnChanges {
  countCol : number = 0;
  @Input() headCheckboxValue: boolean = false;
  @Input() headCheckboxColor: string = ''; // Si tiene un checkbok en la cabecera de que color se pone
  @Input() displayedColumns : string[]  = ['name', 'weight', 'symbol', 'position'];
  columnsToDisplay          : string[]  = this.displayedColumns.slice();
  @Input() head             : any       = {name: '', weight: 'valor 2', symbol: 'valor 3', position: 'valor 4'};
  @Input() data             : any       = [
    {position: 1,  name: 'Hydrogen',  weight: 1.0079,   symbol: 'H'},
    {position: 2,  name: 'Helium',    weight: 4.0026,   symbol: 'He'},
    {position: 3,  name: 'Lithium',   weight: 6.941,    symbol: 'Li'},
    {position: 4,  name: 'Beryllium', weight: 9.0122,   symbol: 'Be'},
    {position: 5,  name: 'Boron',     weight: 10.811,   symbol: 'B'},
    {position: 6,  name: 'Carbon',    weight: 12.0107,  symbol: 'C'},
    {position: 7,  name: 'Nitrogen',  weight: 14.0067,  symbol: 'N'},
    {position: 8,  name: 'Oxygen',    weight: 15.9994,  symbol: 'O'},
    {position: 9,  name: 'Fluorine',  weight: 18.9984,  symbol: 'F'},
    {position: 10, name: 'Neon',      weight: 20.1797,  symbol: 'Ne'},
  ];
  @Output() listenParentHandler : EventEmitter<any> = new EventEmitter();
  @Output() listenParentRow     : EventEmitter<any> = new EventEmitter();
  @Output() selectedRowsFull    : EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedRowsChange  : EventEmitter<any> = new EventEmitter<any>();
  // Paginacion
    @Input() pagination     : boolean = false;
    @Input() paginationDate : PaginationDate = {
      'pageTotal' : 500,
      'pageSize'  : 10,
      'pageIndex' : 0
    };
    @Output() paginationDateEmit : EventEmitter<any> = new EventEmitter();

  async ngOnChanges(changes: SimpleChanges){
    this.columnsToDisplay = this.displayedColumns.slice();
  }

  tableClick(event: MouseEvent, key: any, keyButton: any){
    event.stopPropagation(); 
    this.listenParentHandler.emit({key: key, keyButton: keyButton});
  }

  onRowClick(key: any, type: any = '', event: MouseEvent) {
    // Verifica si el clic proviene del checkbox
    if ( (event.target as HTMLElement).tagName.toLowerCase() === 'input' ) {
      return;
    } else {
      // Si no es un checkbox, emitimos el evento
      this.listenParentRow.emit({ key: key, type: type });
    }
  }

  toggleRowSelection(event: Event, row: any) {
    const checkbox = {
      checked : event,
      rowKey  : row.key
    }
    row.checkbox.value = event;
    this.selectedRowsChange.emit(checkbox);
  }

  toggleSelectAll(event: Event) {
    // Se seleccionan todas los elementos, de la pagina mostrada
    this.data.forEach((row: any) => {
      if(row.checkbox.value !== event){
        row.checkbox.value = event;
      }
    });
    this.selectedRowsFull.emit(event);
  }

  tableHandlePageEvent(event: PageEvent) {
    this.paginationDate.pageSize  = event.pageSize;
    this.paginationDate.pageIndex = event.pageIndex;
    // Emitimos la pagina en la que estamos y los elementos a mostrar
    this.paginationDateEmit.emit(this.paginationDate);
  }
}
