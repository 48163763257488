import { ICollection, IFilter } from "../interfaces/utilitiesInterfaces";
import { ErrorResponse } from "../utils/Response";
import { Bank, BankJSON } from "../models/Bank";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { REGISTRY_URL, NORDIGEN_BANK_URL, BANK_URL } from "../utils/ApiUrls";
import { IBankNordigenSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IBank } from "../aon";
import { BankFilter } from "../utils/ModelsFilters";
import { AonFilter } from "../utils/AonFilter";
import { BankFields } from "../utils/ModelsFields";

export class BankRepository extends GenericRepository<Bank> implements IBankNordigenSpecificMethodsRepository, IMultipleObjectCrudRepository<Bank>, ISingleObjectCrudRepository<Bank> {

    async getCollection(filter?: BankFilter): Promise<ICollection<Bank>> {
        
        let response = await ApiHttpRequest.get(BASE_URL + BANK_URL.GET_BANK_LIST, {}, {});
        let collection: ICollection<Bank> = new Collection<Bank>();
        if(response.length == 0){
            return collection;
        }else{
        response.forEach((element: any) => {
            collection.add(BankJSON.parseDataToReceive(element))
        })
        let active = new AonFilter<BankFields, never, never>();
        active.addField(BankFields.ACTIVE, true);

        return collection.filter(active.getFilter());
        }
    }

    async create(element: Bank): Promise<Bank> {
        let fullRegistry = await ApiHttpRequest.get(BASE_URL + REGISTRY_URL.GET_FULL_REGISTRY, {}, {})
        fullRegistry.banks = []
        delete fullRegistry.addresses;
        delete fullRegistry.media;
        delete fullRegistry.paymethod;
        delete fullRegistry.record_data;
        let jsonBank = {
            account: {
                alias: "",
                code: "",
                description: "",
                domain: +localStorage.getItem('domainId')!
            },
            active: true,
            alias: element.Name,
            bank: "",
            bank_account: element.Iban,
            bic: element.SwiftBic,
            dirty: true,
            domain: +localStorage.getItem('domainId')!,
            fullName: element.Iban + " - " + "",
            iban: element.Iban,
            registry: fullRegistry.id,
            removed: false,
            sufix: ""
        }
        fullRegistry.banks.push(jsonBank);
        let response = await ApiHttpRequest.put(BASE_URL + REGISTRY_URL.SAVE_FULL_REGISTRY, {}, fullRegistry);
        if(!(response?.type! == 'error'))
            return element;
        else
            throw new ErrorResponse('0701');
    }

    async update(element: Bank): Promise<Bank> {
        let fullRegistry = await ApiHttpRequest.get(BASE_URL + REGISTRY_URL.GET_FULL_REGISTRY, {}, {})
        delete fullRegistry.addresses;
        delete fullRegistry.media;
        delete fullRegistry.paymethod;
        delete fullRegistry.record_data;
        fullRegistry.banks.forEach((e:any) => {
            if(e.id == element.getKey()){
                e.alias = element.Name;
                e.bank_account = element.Iban;
                e.bic = element.SwiftBic;
                e.fullName = element.Iban + " - " + "";
                e.iban = element.Iban;
            }   
        });
        let response = await ApiHttpRequest.put(BASE_URL + REGISTRY_URL.SAVE_FULL_REGISTRY, {}, fullRegistry);
        if(!(response?.type! == 'error'))
            return element;
        else
            throw new ErrorResponse('0702');
    }

    async getOneBank(iban: string): Promise<IBank> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_ONE_BANK_ACCOUNT + '?iban=' + iban, {}, {});
        return response;
    }

     async getBalances(): Promise<any> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_BALANCES, {}, {});
        return response;
    }

     async getBalancesOfOneAccount(iban: string): Promise<any> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_BALANCES_OF_ONE_ACCOUNT + '?iban=' + iban, {}, {});
        return response;
    }

     async getMovements(): Promise<any> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_MOVEMENTS, {}, {});
        return response;
    }

     async getMovementsOfOneAccount(iban: string): Promise<any> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_MOVEMENTS_OF_ONE_ACCOUNT + '?iban=' + iban, {}, {});
        return response;
    }

     async getLogo(): Promise<any> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_LOGO, {}, {});
        return response;
    }

    async getLogoOfOneBank(iban: string): Promise<any> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_LOGO_OF_ONE_BANK + '?iban=' + iban, {}, {});
        return response;
    }

     async getLinkedAccounts(): Promise<any> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_LINKED_ACCOUNTS, {}, {});
        return response;
    }

     async getUnlinkedAccounts(): Promise<any> {
     let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_UNLINKED_ACCOUNTS, {}, {});
     return response;   
    }

     async getNordigenLink(iban : string): Promise<any> {
     let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_LINK  + '?iban=' + iban, {}, {});
     return response;   
    }
}