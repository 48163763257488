import { ErrorResponse } from "../utils/Response";
import { ContactType, IAddress } from "../interfaces/modelsInterfaces";
import { ICollection, IFilter, IOrderFilter } from "../interfaces/utilitiesInterfaces"
import { ContactJSON, Contact } from "../models/Contact"
import { GenericRepository } from "./GenericRepository"
import { Collection } from "../utils/Collection";
import { ApiHttpRequest } from "../utils/Http";
import { BASE_URL } from "../utils/Environment";
import { CONTACT_URL, REGISTRY_URL } from "../utils/ApiUrls";
import { IContactSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from '../interfaces/repositoryInterfaces';
import { ContactFields, ContactOrder } from "../utils/ModelsFields";
import { ContactFilter } from "../utils/ModelsFilters";


export class ContactRepository extends GenericRepository<Contact> implements IContactSpecificMethodsRepository, IMultipleObjectCrudRepository<Contact>, ISingleObjectCrudRepository<Contact> {
    async getCollection(filter?: ContactFilter): Promise<ICollection<Contact>> {
        let collection = new Collection<Contact>();
        let params = generateContactFilters(filter);
        let choose = async (i: number) => {
            switch(filter?.fields?.get(ContactFields.TYPE)[i]){
                case ContactType.ACREEDOR:{
                    let response = await ApiHttpRequest.post(BASE_URL + CONTACT_URL.GET_CREDITOR_SUPPLIER_LIST_NEW,{}, params);
                    if(!(response?.type! == 'error'))
                        response.forEach((element: any) => {
                            collection.add(ContactJSON.parseDataToReceiveList(element, filter));
                        });
                    break;
                }
                case ContactType.PROVEEDOR:{
                    let response = await ApiHttpRequest.post(BASE_URL + CONTACT_URL.GET_CREDITOR_SUPPLIER_LIST_NEW, {}, params);
                    if(!(response?.type! == 'error'))
                        response.forEach((element: any) => {
                            collection.add(ContactJSON.parseDataToReceiveList(element, filter));
                        });
                    break;
                }
                case ContactType.CLIENTE:{
                    let response = await ApiHttpRequest.post(BASE_URL + CONTACT_URL.GET_CUSTOMERS_LIST_NEW, {}, params);
                    if(!(response?.type! == 'error'))
                        response.forEach((element: any) => {
                            collection.add(ContactJSON.parseDataToReceiveList(element, filter));
                        });
                    break;
                }
                case ContactType.NULL:{
                    throw new ErrorResponse('1001');
                }
                default:{
                    throw new ErrorResponse('1002');
                }
            }
        }
        if(filter?.fields?.get(ContactFields.TYPE).length > 1){
            for(let i = 0; i < filter?.fields?.get(ContactFields.TYPE).length; i++){
                await choose(i);
            }
        }else{
            await choose(0);
        }
        return collection;
    }

    async get(key: string): Promise<Contact> {
        let id = key.split(';')[0];
        let type = key.split(';')[1];
        let filters = {
            id: id,
            additional_info: ["ADDRESSES", "MEDIA", "BANKS", "PAYMETHOD"]
        }
        switch(type){
            case ContactType.ACREEDOR:{
                let response = await ApiHttpRequest.post(BASE_URL + CONTACT_URL.GET_CREDITOR_LIST +'/'+ id, {}, filters);
                if(!(response?.type! == 'error'))
                    return ContactJSON.parseDataToReceive(response, type);
                break;
            }
            case ContactType.PROVEEDOR:{
                let response = await ApiHttpRequest.post(BASE_URL + CONTACT_URL.GET_SUPPLIERS_LIST + '/'+ id, {}, filters);
                if(!(response?.type! == 'error'))
                return ContactJSON.parseDataToReceive(response, type);
                break;
            }
            case ContactType.CLIENTE:{
                let response = await ApiHttpRequest.post(BASE_URL + CONTACT_URL.GET_CUSTOMERS_LIST + '/' + id, {}, filters);
                if(!(response?.type! == 'error'))
                    return ContactJSON.parseDataToReceive(response, type);
                break;
            }
            case ContactType.NULL:{
                throw new ErrorResponse('1001');
            }
            default:{
                throw new ErrorResponse('1002');
            }
        }
        throw new ErrorResponse('1002');
    }

    async create(contact: Contact): Promise<Contact> {
        switch(contact.Type){           
            case ContactType.ACREEDOR: {
                let response = await ApiHttpRequest.put(BASE_URL + CONTACT_URL.GET_CREDITOR_LIST, {}, ContactJSON.parseDataToSend(contact));
                if(!(response?.type! == 'error')){
                    contact.Key = response.id + ';' + contact.Type;
                    return contact;
                }
                break;
            }
            case ContactType.PROVEEDOR: {
                let response = await ApiHttpRequest.put(BASE_URL + CONTACT_URL.GET_SUPPLIERS_LIST, {}, ContactJSON.parseDataToSend(contact));
                if(!(response?.type! == 'error')){
                    contact.Key = response.id + ';' + contact.Type;
                    return contact;
                }
                break;
            }
            case ContactType.CLIENTE: {
                let response = await ApiHttpRequest.put(BASE_URL + CONTACT_URL.GET_CUSTOMERS_LIST + "/undefined", {}, ContactJSON.parseDataToSend(contact));
                if(!(response?.type! == 'error')){
                    contact.Key = response.id + ';' + contact.Type;
                    return contact;
                }
                break;
            }
            case ContactType.NULL:{
                throw new ErrorResponse('1001');
            }
            default:{
                throw new ErrorResponse('1004');
            }
        }
        throw new ErrorResponse('1004');
    }

    async update(contact: Contact): Promise<Contact> {
        let json = ContactJSON.parseDataToUpdate(contact);
        switch(contact.Type){
            case ContactType.ACREEDOR: {
                let response = await ApiHttpRequest.put(BASE_URL + CONTACT_URL.GET_CREDITOR_LIST, {}, json);
                if(!(response?.type! == 'error'))
                    return contact;
                break;
            }
            case ContactType.PROVEEDOR: {
                let response = await ApiHttpRequest.put(BASE_URL + CONTACT_URL.GET_SUPPLIERS_LIST, {}, json);
                if(!(response?.type! == 'error'))
                    return contact;
                break;
            }
            case ContactType.CLIENTE: {
                let response = await ApiHttpRequest.put(BASE_URL + CONTACT_URL.GET_CUSTOMERS_LIST + "/undefined", {}, json);
                if(!(response?.type! == 'error'))
                    return contact;
                break;
            }
            case ContactType.NULL:{
                throw new ErrorResponse('1001');
            }
            default:{
                throw new ErrorResponse('1005');
            }
        }
        throw new ErrorResponse('1005');
    }

    async getContactFullAddress(key: string): Promise<ICollection<IAddress>> {
        let id = key.split(';')[0];
        let type = key.split(';')[1];
        let filters = {
            id: id,
            additional_info: ["ADDRESSES", "MEDIA", "BANKS", "PAYMETHOD"]
        }
        let response = await ApiHttpRequest.post(BASE_URL + REGISTRY_URL.SAVE_FULL_REGISTRY, {}, filters);
        if(!(response?.type! == 'error'))
            return ContactJSON.parseDataToReceiveFullAddress(response);
        throw new ErrorResponse('1003');
    }

    async countContacts(filter?: ContactFilter): Promise<number> {
        let params = generateContactFilters(filter);
        switch(filter?.fields?.get(ContactFields.TYPE)[0]){
            case ContactType.ACREEDOR:{
                let response = await ApiHttpRequest.post(BASE_URL + CONTACT_URL.GET_CREDITOR_SUPPLIER_COUNT_NEW,{}, params);
                if(!(response?.type! == 'error'))
                    return response.count ? response.count : 0;
                break;
            }
            case ContactType.PROVEEDOR:{
                let response = await ApiHttpRequest.post(BASE_URL + CONTACT_URL.GET_CREDITOR_SUPPLIER_COUNT_NEW, {}, params);
                if(!(response?.type! == 'error'))
                    return response.count ? response.count : 0;
                break;
            }
            case ContactType.CLIENTE:{
                let response = await ApiHttpRequest.post(BASE_URL + CONTACT_URL.GET_CUSTOMERS_COUNT_NEW, {}, params);
                if(!(response?.type! == 'error'))
                    return response.count ? response.count : 0;
                break;
            }
            case ContactType.NULL:{
                throw new ErrorResponse('1001', 'Contact');
            }
            default:{
                throw new ErrorResponse('0206', 'Contact');
            }
        }
        throw new ErrorResponse('0206', 'Contact');
    }
}

let generateContactFilters = (filter?: ContactFilter) => {
    let params: any = {
        additional_info: ["ADDRESSES", "MEDIA", "BANKS", "PAYMETHOD"],
    };
    if (filter?.fields?.has(ContactFields.NAME)) {
        params['value'] = filter.fields?.get(ContactFields.NAME)[0];
    }
    if (filter?.fields?.has(ContactFields.DOCUMENT)) {
        params['value'] = filter.fields?.get(ContactFields.DOCUMENT)[0];
    }
    if (filter?.fields?.has(ContactFields.GLOBAL)) {
        params['global'] = filter.fields?.get(ContactFields.GLOBAL)[0];
    }
    params['perPage'] = filter?.pageItems ? filter.pageItems : 20;
    params['page'] = filter?.pageNum ? filter.pageNum : 1;
    let checkOrder = (filter: IFilter<any, any, any>, contactOrder: ContactOrder, order: IOrderFilter): IOrderFilter => {
        if(filter && filter.orderBy && filter.orderBy.has(contactOrder)){
            order.orderBy += order.orderBy.length == 0 ? contactOrder : ";" + contactOrder;
            order.order += order.order.length == 0 ? filter.orderBy.get(contactOrder) : ";" + filter.orderBy.get(contactOrder);
        }
        return order;
    }
    if(filter && filter.orderBy && filter.orderBy.size > 0){
        let order: IOrderFilter = {
            orderBy: '',
            order: ''
        }
        order = checkOrder(filter, ContactOrder.DOCUMENT, order);
        order = checkOrder(filter, ContactOrder.COUNTRY, order);
        order = checkOrder(filter, ContactOrder.NAME, order);
        if(order.orderBy.length > 0){
            Object.defineProperty(params, 'orderBy', {
                value: order.orderBy,
                enumerable : true,
            })
            Object.defineProperty(params, 'order', {
                value: order.order,
                enumerable : true,
            })
        }
    }
    return params;
}
